<div class="w-full px-0">
  <div
    *ngIf="isLoading"
    class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-500 bg-opacity-50"
  >
    <div class="spinner"></div>
  </div>
  <div
    class="flex mt-20 w-full sm:w-[768px] md:w-[1024px] lg:w-[1200px] default-font"
  >
    <div class="w-full p-6 bg-white rounded shadow">
      <ng-container *ngIf="!isLoggedIn">
        <h2 class="text-3xl mt-5 mb-4">Login to articles</h2>
        <!-- Display login form if not logged in -->
        <form (submit)="login()">
          <div class="mb-4">
            <label
              for="username"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              class="block w-full border-2 border-solid border-blue-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              [(ngModel)]="username"
            />
          </div>
          <div class="mb-4">
            <label
              for="password"
              class="block text-gray-700 text-sm font-bold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              class="block border-2 border-solid w-full border-blue-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              [(ngModel)]="password"
            />
          </div>
          <button
            type="submit"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Sign In
          </button>
        </form>
      </ng-container>

      <!-- Display form elements if logged in -->
      <ng-container *ngIf="isLoggedIn">
        <h1 class="text-3xl">Welcome, {{ username }}!</h1>
        <form (submit)="saveArticle()">
          <div class="mb-3">
            <label
              for="story"
              class="block text-gray-700 text-md font-bold mb-2"
              >Title</label
            >
            <input
              type="text"
              id="title"
              class="w-full border-blue-300 border-solid border-2 rounded focus:outline-none px-3 py-2 focus:border-blue-500"
              [value]="title"
              (input)="title = $event.target.value"
            />
          </div>
          <div class="mb-3">
            <label
              for="story"
              class="block text-gray-700 text-md font-bold mb-2"
              >History</label
            >
            <textarea
              id="story"
              rows="5"
              class="block w-full border-2 border-solid border-blue-300 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
              [(ngModel)]="story"
            >
            </textarea>
          </div>

          <div class="mb-3 flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Click to upload</span> or drag and
                  drop
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF, WEBM, MP4. Max 100MB
                </p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                class="hidden"
                (change)="handleFileInput($event)"
                accept=".svg,.png,.jpeg,.gif,.webm,.mp4"
              />
              <img
                *ngIf="previewType == 'image'"
                [src]="filedata"
                alt="Preview"
                class="mt-4 w-32 h-32"
              />
              <video
                loop
                controlsList="nodownload"
                class="w-full object-contain"
                controls
                *ngIf="previewType == 'video'"
                [src]="filedata"
              >
                <source type="video/webm" [src]="filedata" />
                <source type="video/mp4" [src]="filedata" />
              </video>
            </label>
          </div>

          <div class="mb-3">
            <label
              for="story"
              class="block text-gray-700 text-md font-bold mb-2"
              >Is it Video?</label
            >
            <input [value]="isvideo" type="checkbox" />
          </div>
          <div class="mb-3">
            <label
              for="videolink"
              class="block text-gray-700 text-md font-bold mb-2"
              >Add image or video link</label
            >
            <input
              type="text"
              id="videolink"
              class="w-full border-blue-300 border-solid border-2 rounded focus:outline-none px-3 py-2 focus:border-blue-500"
              [value]="videolink"
              (input)="videolink = $event.target.value"
            />
          </div>
          <div class="mb-3">
            <label
              for="vinlink"
              class="block text-gray-700 text-md font-bold mb-2"
              >Add link of vin tracker</label
            >
            <input
              type="text"
              id="vinlink"
              class="w-full border-blue-300 border-solid border-2 rounded focus:outline-none px-3 py-2 focus:border-blue-500"
              [value]="vinlink"
              (input)="vinlink = $event.target.value"
            />
          </div>
          <button
            class="w-full bg-green-500 hover:bg-green-700 text-center text-white font-bold py-2 px-4 rounded mb-3"
          >
            Save Article
          </button>
          <button
            *ngIf="editFlag"
            class="w-full bg-red-500 hover:bg-red-700 text-center text-white font-bold py-2 px-4 rounded"
            (click)="cancel()"
          >
            Cancel
          </button>
        </form>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div *ngFor="let article of articles">
            <app-article-section
              [article]="article"
              (updateEvent)="updateArticle($event)"
              (removeEvent)="removeArticle($event)"
            ></app-article-section>
          </div>
        </div>
        <!-- Add your additional form elements here -->
      </ng-container>
    </div>
  </div>
</div>
