<div class="mt-16 w-full px-4 sm:px-0 sm:w-[90%] md:w-[80%] mx-auto">
  <div
    class="text-2xl page-title sm:text-2xl flex flex-col sm:flex-row justify-center items-center default-font font-bold mb-5 gap-3"
    [routerLink]="'/marketplace'"
  >
    <img
      src="../../../assets/images/vin-track-new-black.png"
      class="w-[250px] md:w-[300px]"
    />
    MARKETPLACE
  </div>
  <div
    class="flex flex-col loadingBox absolute inset-0 items-center pt-32 rounded-lg z-10"
    *ngIf="loading"
  >
    <app-vin-spinner></app-vin-spinner>
    <div class="mt-2 text-2xl font-bold">Marketplace is loading...</div>
  </div>
  <div
    class="mt-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 sm:gap-2 md:gap-4"
  >
    <div *ngFor="let product of products" class="relative">
      <app-single-marketplace-item-card
        [singleItem]="product"
      ></app-single-marketplace-item-card>
    </div>
  </div>
</div>
