<div class="contact-section">
  <br />
  <br />
  <br />
  <h2>Contact Us</h2>
  <p>
    Join the conversation at ServiceRecords, where automotive passion meets
    technology. Whether you're a collector, professional, part of a dealership,
    or simply passionate about cars and technology your perspectives shape our
    journey. We're all about fostering connections in the automotive world, for
    both enthusiasts and industry insiders. Get in touch for inquiries, ideas,
    or partnership possibilities. Together, let's drive innovation in the
    automotive sphere.
  </p>

  <div class="contact-details">
    <a href="mailto:info@vin-tracker.com?subject=Inquiry"
      >info@vin-tracker.com</a
    >
    <br />
    <br />

    <button class="bg-redLeather" (click)="copyEmail()">Copy Email</button>
    <br />
    <br />
    <br />
  </div>

  <div class="social-links">
    <a
      href="https://www.instagram.com/vintracker/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
        alt="Instagram"
      />
    </a>
    <a
      href="https://www.youtube.com/channel/UCeukuPGIBRdDNKmUcffwnkA"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
        alt="YouTube"
      />
    </a>
    <a
      href="https://medium.com/@socials_60910"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/e/ec/Medium_logo_Monogram.svg"
        alt="Medium"
      />
    </a>
  </div>
</div>
