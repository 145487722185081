<div class="buttons">
  <div *ngIf="!profile">
    <button
      class="offset"
      onClick="route()"
      [routerLink]="['/create/enthusiast']"
    >
      Push to list <br />
      your <BR /> Vehicle
    </button>
  </div>

  <div *ngIf="profile == 'collector'">
    <button
      class="offset"
      onClick="route()"
      [routerLink]="['/create/enthusiast']"
    >
      Push to list <br />
      your <BR /> Vehicle+
    </button>
  </div>

  <div *ngIf="profile == 'enthusiast'">
    <button
      class="offset"
      onClick="route()"
      [routerLink]="['/create/enthusiast']"
    >
      Push to start <br />
      your <BR /> ServiceRecords
    </button>
  </div>

  <div *ngIf="profile == 'professional'">
    <button
      class="offset"
      onClick="route()"
      [routerLink]="['/create/professional']"
    >
      Push to list <br />
      your <BR /> Vehicle++
    </button>
  </div>
</div>
