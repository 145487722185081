<head>
  <!-- Google tag (gtag.js) -->
  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=G-G4EFZRR20D"
  ></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-G4EFZRR20D");
  </script>
</head>
<div class="relative pt-8 mt-16">
  <div class="xl:w-[1156px] mx-auto lg:w-[900px] w-full relative">
    <div
      class="flex flex-col loadingBox absolute inset-0 items-center justify-center rounded-lg z-10 mt-20"
      *ngIf="loading || processing"
    >
      <app-vin-spinner></app-vin-spinner>
      <div class="mt-2 text-2xl font-bold afont">Opening Garage Gate...</div>
    </div>
    <div *ngIf="!loading" class="px-6 md:px-0">
      <!-- Main Info -->
      <div class="flex flex-col md:flex-row gap-8">
        <div class="item-image">
          <img
            src="{{ singleItem.image }}"
            alt="item"
            *ngIf="!singleItem?.isVideo"
            (click)="openZoom(0)"
          />
          <video
            loop
            controlsList="nodownload"
            class="object-contain rounded-xl"
            controls
            *ngIf="singleItem?.isVideo"
            (click)="openZoom(0)"
          >
            <source type="video/webm" [src]="singleItem.image + '\#t=0.001'" />
            <source type="video/mp4" [src]="singleItem.image + '\#t=0.001'" />
          </video>
          <button
            class="favorite flex items-center justify-center"
            (click)="toggleFavorite()"
          >
            <i class="material-icons" [class.favorited]="isFavorited"
              >favorite</i
            >
          </button>
          <div
            *ngIf="singleItem.isForSale === 'Yes' && listPrice"
            class="priceBanner"
          >
            {{ listPrice }}
          </div>
        </div>
        <div class="item-content">
          <button
            class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            *ngIf="isYours"
            type="button"
            (click)="openUpgradeModal()"
          >
            To store records and 250 media ($10 upgrade)
          </button>
          <div class="item-content-title flex items-center gap-4">
            <h1>{{ singleItem.name }}</h1>
            <img
              src="../../../assets/images/qrcode.png"
              class="w-8 h-8 cursor-pointer mt-0.5"
              (click)="openModal('qrCodeModal')"
            />
            <div class="dropdown relative">
              <button type="button" class="dropdown-toggle">
                <i class="fas fa-share-alt text-xl text-black mt-0.5"></i>
              </button>
              <div
                class="pt-4 section-dropdown bg-transparent absolute md:-left-3 z-50"
              >
                <ul
                  class="shadow-md py-1 flex flex-col bg-white text-black px-2"
                >
                  <li class="w-52 py-1.5 px-1">
                    <a
                      class="flex items-center gap-2"
                      [href]="
                        'https://www.facebook.com/sharer/sharer.php?u=https://vin-tracker.com/item/' +
                        singleItem.id
                      "
                      target="_blank"
                    >
                      <img
                        src="../../../assets/images/facebook.png"
                        class="w-6 h-6"
                        title="Share on Facebook"
                      />
                      <span class="font-semibold text-black"
                        >Share on Facebook</span
                      >
                    </a>
                  </li>
                  <li class="w-52 mt-2 py-1.5 px-1">
                    <a
                      class="flex items-center gap-2"
                      [href]="
                        'https://twitter.com/intent/tweet?text=' +
                        singleItem.name +
                        '&url=https%3A%2F%2Fvin-tracker.com%2Fitem%2F' +
                        singleItem.id +
                        '&via=vin-tracker'
                      "
                      target="_blank"
                    >
                      <img
                        src="../../../assets/images/twitter.svg"
                        class="w-6 h-6"
                        title="Share on Twitter"
                      />
                      <span class="font-semibold text-black"
                        >Share on Twitter</span
                      >
                    </a>
                  </li>
                  <li class="w-52 mt-2 py-1.5 px-1">
                    <button class="flex items-center gap-3" (click)="copyUrl()">
                      <i class="fas fa-link text-xl text-blue-500"></i>
                      <span class="font-semibold text-black">Copy URL</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <span
              *ngIf="vinEstimate != '0'"
              class="estimate"
              style="color: rgb(22, 94, 22)"
            >
              VIN-ESTIMATE: {{ vinEstimate }}
            </span>
            <h4 class="ledFont1 color-crimson">
              VIN SN : {{ singleItem.vin }}
            </h4>
            <h4 class="raceFont3">ServiceRecords holder</h4>
            <div class="hidden md:block">
              <a [routerLink]="['/garage', singleItem.owner]" class="raceFont1"
                >{{ getOwnerName() }}
              </a>
            </div>
            <div class="md:hidden">
              <a [routerLink]="['/garage', singleItem.owner]" class="raceFont1"
                >{{ getOwnerName() }}
              </a>
            </div>
          </div>
          <!-- <div class="flex items-center gap-4 mt-4">
            <a [href]="'https://www.facebook.com/sharer/sharer.php?u=https://vin-tracker.com/item/' + singleItem.id" target="_blank">
              <img src="../../../assets/images/facebook.png" class="w-8 h-8" title="Share on Facebook" />
            </a>
            <a [href]="'https://twitter.com/intent/tweet?text=' + singleItem.name + '&url=https%3A%2F%2Fvin-tracker.com%2Fitem%2F' + singleItem.id + '&via=vin-tracker'" target="_blank">
              <img src="../../../assets/images/twitter.svg" class="w-8 h-8" title="Share on Twitter" />
            </a>
          </div> -->
          <div
            *ngFor="let paragraph of paragraphs; let i = index"
          >
            <div *ngIf="i === 0" class="item-content-detail afont">
              <p [innerHTML]="convertString(paragraph)"></p>
            </div>
            <div *ngIf="i !== 0" class="item-content-detail-sub afont">
              <p [innerHTML]="convertString(paragraph)"></p>
            </div>
            <div *ngIf="i != paragraph_count - 1" class="item-image item-image-xs">
              <img
                src="{{ singleItem.extras[i % extra_count].content }}"
                alt="item"
                *ngIf="!singleItem.extras[i % extra_count]?.isVideo"
              />
              <video
                loop
                controlsList="nodownload"
                class="object-contain rounded-xl"
                controls
                *ngIf="singleItem.extras[i % extra_count]?.isVideo"
              >
                <source type="video/webm" [src]="singleItem.extras[i % extra_count].content + '\#t=0.001'" />
                <source type="video/mp4" [src]="singleItem.extras[i % extra_count].content + '\#t=0.001'" />
              </video>
            </div>
          </div>
          <div *ngIf="singleItem.links" class="item-content-detail afont">
            <p [innerHTML]="convertString(singleItem.links)"></p>
          </div>
          <div
            class="item-content-buy flex flex-col md:flex-row gap-4 items-center"
          >
            <a
              [routerLink]="['/update', singleItem.id]"
              *ngIf="isYours"
              class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            >
              Update Records
            </a>

            <button
              *ngIf="isYours"
              type="button"
              (click)="openModal('update-password-modal')"
              class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            >
              Manage Vault Access
            </button>

            <button
              (click)="openModal('transfer-modal')"
              *ngIf="isYours"
              class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            >
              Transfer Ownership
            </button>

            <button
              (click)="openModal('delete-confirm-modal')"
              *ngIf="isYours"
              class="danger-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            >
              Delete This ServiceRecord
            </button>

            <button
              (click)="claim_vintracker()"
              *ngIf="!isYours"
              class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            >
              Claim Ownership
            </button>

            <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
              <div *ngFor="let attribute of singleItem.attributes">
                <div
                  class="card w-full bg-pedal"
                  *ngIf="attribute.trait_type == 'Asking Price'"
                >
                  <h2 class="text-center font-semibold text-lg raceFont3">
                    {{ attribute.trait_type }}
                  </h2>
                  <p class="text-center justify afont">{{ attribute.value }}</p>
                </div>
              </div>
            </div>

            <!-- <button
              (click)="openModal('make-offer-modal')"
              *ngIf="isYours"
              class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto mt-4 md:mt-0"
            >
              List for Sale
            </button> -->

            <button
              (click)="openModal('make-offer-modal')"
              *ngIf="!isYours && (walletAddress || auth.isLogin)"
              class="offer-button"
            >
              Propose Offer
            </button>

            <button
              *ngIf="!walletAddress && !auth.isLogin"
              class="login-button"
              (click)="openModal('login-modal')"
            >
              Please login or sign up to make offers
            </button>
          </div>
        </div>
      </div>

      <!-- Additional Medias -->
      <div class="my-12" *ngIf="singleItem?.extras?.length > 0">
        <div class="item-content-title">
          <h1 class="my-4 md:my-0 md:mt-20">Additional Media</h1>
        </div>
        <div class="flex flex-row flex-wrap gap-6 mt-4">
          <div
            *ngFor="let _extra of singleItem?.extras; let i = index"
            class="relative"
            (click)="openZoom(i + 1)"
          >
            <img
              src="{{ _extra.content }}"
              class="h-28 object-contain rounded-xl"
              *ngIf="!_extra.isVideo"
            />
            <video
              loop
              controlsList="nodownload"
              class="h-28 object-contain rounded-xl"
              controls
              *ngIf="_extra.isVideo"
            >
              <source type="video/webm" [src]="_extra.content + '\#t=0.001'" />
              <source type="video/mp4" [src]="_extra.content + '\#t=0.001'" />
            </video>
          </div>
        </div>
      </div>

      <!-- Attributes & Chain Info -->
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <!-- Attributes -->
        <div *ngIf="singleItem.attributes?.length > 0">
          <div class="item-content-title">
            <h1 class="my-4 md:my-0 md:mt-20 raceFont3">VIN Specs</h1>
          </div>
          <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
            <div
              class="card w-full bg-pedal"
              *ngFor="let attribute of singleItem.attributes"
            >
              <h2 class="text-center font-semibold text-lg raceFont3">
                {{ attribute.trait_type }}
              </h2>
              <p class="text-center justify afont">{{ attribute.value }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          singleItem.hasPassword ||
          singleItem?.records?.ext_record?.length > 0 ||
          singleItem?.records?.service_record?.length > 0 ||
          singleItem?.records?.ownership?.length > 0
        "
      >
        <div class="item-content-title">
          <h1 class="my-12 md:my-0 md:mt-20 raceFont3">
            Document Storage Vault
          </h1>
        </div>
        <div
          *ngIf="showPasswordPopup"
          class="px-8 py-6 bg-white shadow-lg rounded-xl border border-gray-200 mt-4 bg-pedal"
          id="pwbox"
        >
          <div class="flex items-center justify-center gap-3 md:gap-8">
            <img
              src="../../../assets/images/pw2-trans.png"
              class="h-24 md:h-32 w-24 md:w-32"
            />
            <div class="flex flex-col mt-5 flex-1 color-white">
              <div class="text-xl font-semibold">Password:</div>
              <input
                type="password"
                (keyup.enter)="enterPassword()"
                class="px-3 py-1.5 border border-gray-300 bg-transparent outline-none rounded-lg mt-3"
                [(ngModel)]="password"
                placeholder="Enter Password..."
              />
              <span class="mt-1 text-sm font-medium text-red-600 h-5">{{
                pwValidText
              }}</span>
              <button
                class="bg-vin-blue writeButton mt-2 bg-redLeather"
                (click)="enterPassword()"
              >
                Access Vault
              </button>
            </div>
          </div>
          <div
            class="font-semibold text-center mt-1 md:mt-2 opacity-80 text-sm md:text-base color-white"
          >
            Enter password to unlock the data vault.
          </div>
        </div>

        <div
          class="mt-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2"
          *ngIf="!showPasswordPopup"
        >
          <div
            class="card w-full flex items-center justify-center bg-redLeather cursor-pointer"
            (click)="
              recordPopupType = 'service_record';
              openModal('external-record-list-modal')
            "
            *ngIf="singleItem?.records?.service_record?.length > 0"
          >
            <h2 class="text-center font-semibold text-lg afont">
              Service Records
            </h2>
          </div>
          <div
            class="card w-full flex items-center justify-center bg-redLeather cursor-pointer"
            (click)="
              recordPopupType = 'ownership';
              openModal('external-record-list-modal')
            "
            *ngIf="singleItem?.records?.ownership?.length > 0"
          >
            <h2 class="text-center font-semibold text-lg afont">
              Ownership & Registration
            </h2>
          </div>
          <div
            class="card w-full flex items-center justify-center bg-redLeather cursor-pointer"
            (click)="
              recordPopupType = 'ext_record';
              openModal('external-record-list-modal')
            "
            *ngIf="singleItem?.records?.ext_record?.length > 0"
          >
            <h2 class="text-center font-semibold text-lg afont">
              Other Documents
            </h2>
          </div>
        </div>

        <div
          class="item-content-buy flex flex-col md:flex-row md:gap-4 items-center"
        >
          <div *ngIf="isYours">
            <a
              [routerLink]="['/update', singleItem.id]"
              *ngIf="!showPasswordPopup && isYours"
              class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            >
              Add Records
            </a>

            <button
              *ngIf="!showPasswordPopup && isYours"
              type="button"
              (click)="openModal('update-password-modal')"
              class="secondary-btn hover:opacity-90 active:opacity-75 w-full text-center md:w-auto"
            >
              Lock Vault
            </button>
          </div>
        </div>
      </div>
      <!-- Comments -->
      <div class="my-12">
        <div class="item-content-title">
          <h1 class="mb-4 raceFont3">Comments</h1>
        </div>
        <div *ngIf="!walletAddress && !auth.isLogin">
          <span class="text-center text-lg italic font-semibold afont">
            <button
              class="primary-btn hover:opacity-90 active:opacity-75 bg-carbon1 w-full text-center md:w-auto mt-4 md:mt-0"
              (click)="openModal('login-modal')"
            >
              Please Login or sign up to comment
            </button>
          </span>
        </div>
        <div
          *ngIf="walletAddress || auth.isLogin"
          class="flex flex-col items-end afont"
        >
          <textarea
            class="comment-area w-full afont"
            placeholder="New Comment..."
            [(ngModel)]="newComment"
          >
          </textarea>
          <button
            class="bg-vin-blue writeButton mt-2"
            (click)="createComment()"
          >
            Submit
          </button>
        </div>
        <div
          class="flex flex-col gap-6 border-t border-dashed border-black border-opacity-50 mt-4 pt-4"
          *ngIf="comments?.length > 0"
        >
          <div
            *ngFor="let _comment of comments"
            class="rounded-lg p-4 relative w-full bg-gray-200 font-medium"
          >
            <div
              class="absolute -top-3 left-4 flex flex-col items-center cursor-pointer"
              [routerLink]="'/profile/' + _comment.writer"
            >
              <img
                src="../../../assets/images/caratar.jpeg"
                alt="profile"
                width="60"
                height="60"
                class="rounded-full"
              />
              <span class="text-sm" *ngIf="_comment.writer?.startsWith('0x')">
                {{ _comment.writer.slice(0, 6) }}...{{
                  _comment.writer.slice(_comment.writer?.length - 6)
                }}
              </span>
              <span class="text-sm" *ngIf="!_comment.writer?.startsWith('0x')">
                {{ _comment.writer }}
              </span>
            </div>
            <div class="ml-32 flex gap-1">
              <span
                class="text-lg whitespace-pre-line"
                *ngIf="eComment?._id !== _comment._id"
                >{{ _comment.comment }}</span
              >
              <textarea
                class="w-full p-4 rounded-lg"
                placeholder="Update Comment..."
                *ngIf="eComment?._id === _comment._id"
                [(ngModel)]="eComment.comment"
              >
              </textarea>
            </div>
            <div class="flex items-center justify-end gap-1 mt-1 text-sm">
              <i class="fas fa-calendar-day"></i>
              {{ getCommentTime(_comment.commentDate) }}
            </div>
            <div
              class="flex items-center justify-end gap-2 mt-1 text-sm"
              *ngIf="
                _comment.writer?.toLowerCase() ===
                  walletAddress?.toLowerCase() ||
                _comment.writer === auth.curUser?.username
              "
            >
              <button
                *ngIf="eComment?._id !== _comment._id"
                class="flex items-center gap-0.5 hover:opacity-90 active:opacity-75 py-1 px-2 rounded-md bg-green-600 font-semibold text-white"
                (click)="editComment(_comment)"
              >
                <i class="fas fa-edit"></i>
                Edit
              </button>
              <button
                *ngIf="eComment?._id !== _comment._id"
                class="flex items-center gap-0.5 hover:opacity-90 active:opacity-75 py-1 px-2 rounded-md bg-red-600 font-semibold text-white"
                (click)="deleteComment(_comment._id)"
              >
                <i class="fas fa-times-circle"></i>
                Delete
              </button>
              <button
                *ngIf="eComment?._id === _comment._id"
                class="flex items-center gap-0.5 hover:opacity-90 active:opacity-75 py-1 px-2 rounded-md bg-blue-600 font-semibold text-white"
                (click)="updateComment()"
              >
                <i class="fas fa-save"></i>
                Update
              </button>
              <button
                *ngIf="eComment?._id === _comment._id"
                class="flex items-center gap-0.5 hover:opacity-90 active:opacity-75 py-1 px-2 rounded-md bg-gray-600 font-semibold text-white"
                (click)="eComment = null"
              >
                <i class="fas fa-times-circle"></i>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="vinEstimate != '0'" class="estimate">
        {{ singleItem.name }} VIN-ESTIMATE: {{ vinEstimate }}
      </div>
      <br />
      <div class="text-center">
        <button
          (click)="openModal('make-offer-modal')"
          *ngIf="!isYours && (walletAddress || auth.isLogin)"
          class="offer-button"
        >
          Propose Offer
        </button>
        <br />
      </div>
      <hr
        style="
          height: 2px;
          width: 80%;
          max-width: 400px;
          margin: 20px auto;
          background-color: #4c6ead;
          transform: rotate(-3deg);
        "
      />
    </div>
  </div>

  <jw-modal id="make-offer-modal" class="long-modal">
    <div class="relative">
      <!-- Modify this button to match the previous modal's close button style -->
      <button
        class="absolute top-1 right-2 text-2xl text-white"
        (click)="closeModal('make-offer-modal')"
        style="color: whitesmoke"
      >
        <i class="fas fa-times"></i>
      </button>

      <h2
        class="text-2xl font-semibold mb-4 bg-carbon2 raceFont1 text-white pl-2"
      >
        Propose an Offer
      </h2>

      <p class="mt-6 font-medium">
        Propose an offer to buy car through ServiceRecords and dealer services.
      </p>
      <div
        class="mt-6 rounded-md border-vin-blue border-2 py-1 px-2 bg-vin-blue bg-opacity-60"
      >
        <div class="flex flex-row items-center justify-between font-medium">
          <span>Input Offer Amount</span>
        </div>
        <div class="mt-2 flex items-center gap-0.5">
          <span class="font-bold text-lg">$</span>
          <input
            class="outline-none appearance-none w-full text-black text-opacity-90 text-lg font-medium bg-transparent placeholder-white"
            placeholder="0"
            [(ngModel)]="offerAmount"
          />
        </div>
      </div>

      <div class="flex justify-center mt-4">
        <button
          class="primary-btn w-3/4"
          (click)="makeOffer()"
          *ngIf="!processing"
        >
          Make Offer
        </button>
        <button
          class="primary-btn w-3/4"
          (click)="closeModal('make-offer-modal')"
          *ngIf="!processing"
        >
          Cancel
        </button>
        <button class="primary-btn w-3/4" *ngIf="processing">
          Processing...
        </button>
      </div>
    </div>
  </jw-modal>

  <jw-modal id="imageGalleryModal" class="long-modal">
    <div class="modal-dialog" role="document">
      <button
        *ngIf="gallerySlides.length > 1"
        class="absolute left-0 top-0 bottom-0 h-full chevron-left px-8"
        (click)="prevSlider()"
      >
        <i class="fas fa-chevron-left text-4xl text-white"></i>
      </button>
      <button
        *ngIf="gallerySlides.length > 1"
        class="absolute right-0 top-0 bottom-0 h-full chevron-right px-8"
        (click)="nextSlider()"
      >
        <i class="fas fa-chevron-right text-4xl text-white"></i>
      </button>

      <button
        class="absolute top-2 right-2 text-2xl text-white"
        (click)="closeModal('imageGalleryModal')"
      >
        <i class="fas fa-times"></i>
      </button>

      <div class="modal-content" *ngIf="gallerySlides[galleryPos]">
        <img
          *ngIf="!gallerySlides[galleryPos].isVideo"
          [src]="gallerySlides[galleryPos].content"
          alt="image"
        />
        <video *ngIf="gallerySlides[galleryPos].isVideo" controls loop>
          <source type="video/webm" [src]="gallerySlides[galleryPos].content" />
          <source type="video/mp4" [src]="gallerySlides[galleryPos].content" />
        </video>
      </div>
    </div>
  </jw-modal>

  <jw-modal id="qrCodeModal" class="no-min-w-modal">
    <qrcode
      [qrdata]="qrCodeData"
      [allowEmptyString]="true"
      [width]="256"
      [errorCorrectionLevel]="'M'"
      (qrCodeURL)="onChangeURL($event)"
    ></qrcode>
    <div class="flex justify-center">
      <a
        [href]="qrCodeDownloadLink"
        [download]="singleItem?.name"
        class="bg-vin-blue rounded-xl font-semibold text-sm text-white py-3 px-8 w-3/4 text-center"
      >
        Download QR
      </a>
    </div>
  </jw-modal>

  <jw-modal id="external-record-list-modal" class="no-min-h-modal">
    <button
      class="relative top-2 right-2 text-2xl text-black float-right"
      (click)="closeModal('external-record-list-modal')"
    >
      <i class="fas fa-times"></i>
    </button>
    <div
      class="flex flex-col gap-4"
      *ngIf="singleItem?.records && singleItem?.records[recordPopupType]"
    >
      <a
        *ngFor="let item of singleItem?.records[recordPopupType]"
        target="_blank"
        [href]="item.url"
        class="flex items-center justify-center gap-2 text-white bg-redLeather px-5 py-3 rounded-xl"
      >
        <span class="font-semibold">{{ item.type }}</span>
        <i class="fas fa-external-link-alt"></i>
      </a>
    </div>
  </jw-modal>

  <div *ngIf="!loading" class="text-center">
    <app-start-vin-button></app-start-vin-button>
  </div>

  <!-- BLOCKCHAIN INFO -->
  <div *ngIf="!loading" class="relative pt-8">
    <div class="xl:w-[1156px] mx-auto lg:w-[900px] w-full relative">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8"></div>
      <div class="px-3">
        <div class="item-content-title">
          <h1 class="my-4 md:my-0 md:mt-20 raceFont3">Blockchain Info</h1>
        </div>
        <div class="mt-4 grid grid-cols-1 sm:grid-cols-3 gap-2">
          <div class="card w-full bg-surface_blue">
            <h2 class="text-center font-semibold text-lg raceFont3">
              Holder's Address
            </h2>
            <p class="text-center justify afont">
              {{ sliceWallet(nftAddress) }}
            </p>
          </div>

          <div class="card w-full bg-surface_blue">
            <h2 class="text-center font-semibold text-lg raceFont3">
              Token Id
            </h2>
            <p class="text-center justify afont">{{ singleItem.id }}</p>
          </div>
          <div class="card w-full bg-surface_blue">
            <h2 class="text-center font-semibold text-lg raceFont3">
              Blockchain
            </h2>
            <p class="text-center justify afont">Polygon</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Update Password -->
<jw-modal id="update-password-modal">
  <button
    class="relative top-2 right-2 text-2xl text-black float-right"
    (click)="closeModal('update-password-modal')"
  >
    <i class="fas fa-times"></i>
  </button>
  <div class="flex flex-col items-center">
    <h2 class="text-2xl font-semibold text-center mb-6">
      Update Vault Password
    </h2>
    <input
      *ngIf="curPassword.length > 0"
      type="password"
      placeholder="Enter vault password or leave blank if never set"
      required
      [(ngModel)]="formOldPassword"
      class="vin-input"
    />
    <input
      type="password"
      placeholder="Enter new password..."
      required
      [(ngModel)]="formNewPassword"
      class="vin-input mt-4"
    />
    <input
      type="password"
      placeholder="Repeat password..."
      required
      [(ngModel)]="formNewConfirmPassword"
      class="vin-input mt-2"
    />
    <div class="flex justify-center">
      <button
        class="writeButton bg-vin-blue mt-6"
        *ngIf="!isUpdatingPassword"
        (click)="updatePassword()"
      >
        Update
      </button>
      <button class="writeButton bg-vin-blue mt-6" *ngIf="isUpdatingPassword">
        Securing Vault Access...
      </button>
    </div>
    <br />
    <h2 class="text-xl font-semibold text-center mb-6">
      You may leave the new password blank to make records available publically.
    </h2>
  </div>
</jw-modal>

<!-- Transfer Ownerhip -->
<jw-modal id="transfer-modal">
  <div class="flex flex-col items-center p-4">
    <h2 class="text-lg font-bold mb-4">
      Transfer ServiceRecord Ownership of {{ singleItem.name }}
    </h2>

    <p class="mb-3">
      To transfer ownership of this ServiceRecord please submit the email
      address of the recipient below.
    </p>
    <p class="mb-3">
      You may also email us at <b>tech@vin-tracker.com</b> with a request to
      transfer ownership.
    </p>

    <div class="flex flex-col w-full mb-3">
      <label for="recipient-email" class="mb-1">Recipient's Email:</label>
      <input
        type="email"
        id="recipient-email"
        class="border p-2"
        [(ngModel)]="xfer_recipient_email"
        placeholder="Enter email address of the intended recipient."
      />
    </div>

    <div class="flex flex-col w-full mb-3">
      <label for="user-email" class="mb-1">Memo for this transfer:</label>
      <input
        type="text"
        id="transfer-memo-"
        class="border p-2"
        [(ngModel)]="xfer_memo"
        placeholder="Enter an optional memo to be shared with the new owner or vin-tracker team."
      />
    </div>

    <div class="flex flex-col w-full mb-3">
      <label for="user-email" class="mb-1">Your Email:</label>
      <input
        type="email"
        id="user-email"
        class="border p-2"
        [(ngModel)]="xfer_holder_email"
        placeholder="Enter your email address"
      />
    </div>

    <div class="flex flex-col w-full mb-3">
      <label for="user-password" class="mb-1">Your Password:</label>
      <input
        type="password"
        id="user-password"
        class="border p-2"
        [(ngModel)]="xfer_holder_password"
        placeholder="Enter your website password."
      />
    </div>

    <div class="flex justify-between w-full">
      <button
        class="border bg-blue-500 text-white p-2 rounded"
        id="submit-button"
        (click)="xfer_vintracker()"
      >
        Submit
      </button>

      <button
        class="border bg-gray-300 p-2 rounded"
        id="cancel-button"
        (click)="closeModal('transfer-modal')"
      >
        Cancel
      </button>
    </div>
  </div>

  <div class="flex flex-col items-center p-4 hidden" id="confirmation-message">
    <h2 class="text-lg font-bold mb-4">Thank You!</h2>
    <p>We received your request and will process it shortly.</p>
    <button
      class="border bg-gray-300 p-2 rounded"
      id="close-xfer-modal-button"
      (click)="closeModal('transfer-modal')"
    >
      Close
    </button>
  </div>
</jw-modal>

<!-- Claim Ownership -->
<jw-modal id="claim-modal">
  <div class="flex flex-col items-center">
    <h2 class="text-lg font-bold mb-4">
      Claim Ownership of {{ singleItem.name }}
    </h2>
    <p class="mb-3">
      To claim ownership of this ServiceRecord please submit your email address
      and a note about how can you verify your ownership.
    </p>
    <p class="mb-3">
      You may also email us at <b>tech@vin-tracker.com</b> with a request to
      claim ownership.
    </p>
    <div class="flex flex-col w-full mb-3">
      <label for="user-email" class="mb-1"
        >Your Email (So we can get back to you):</label
      >
      <input
        type="email"
        id="user-email"
        class="border p-2"
        [(ngModel)]="xfer_recipient_email"
        placeholder="Enter your email address"
      />
    </div>

    <div class="flex flex-col w-full mb-3">
      <label for="user-email" class="mb-1">Ownership proof information:</label>
      <input
        type="text"
        id="transfer-memo-"
        class="border p-2"
        [(ngModel)]="xfer_memo"
        placeholder="Such as title, regitration, or agreement with owner, etc. We may contact you later with secure methods of verification."
      />
    </div>

    <div class="flex justify-between w-full">
      <button
        class="border bg-blue-500 text-white p-2 rounded"
        id="submit-button"
        (click)="claim_vintracker()"
      >
        Submit
      </button>
      <button
        class="border bg-gray-300 p-2 rounded"
        id="cancel-button"
        (click)="closeModal('claim-modal')"
      >
        Cancel
      </button>
    </div>
  </div>

  <div class="flex flex-col items-center p-4 hidden" id="confirmation-message">
    <h2 class="text-lg font-bold mb-4">Thank You!</h2>
    <p>We received your request and will process it shortly.</p>
    <button
      class="border bg-gray-300 p-2 rounded"
      id="close-xfer-modal-button"
      (click)="closeModal('claim-modal')"
    >
      Close
    </button>
  </div>
</jw-modal>

<jw-modal id="select-upgrade-mode-modal">
  <div class="relative flex flex-col items-center justify-between pt-6">
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('select-upgrade-mode-modal')"
    ></i>
    <img src="../../../assets/images/cc.png" width="35" />
    <button
      class="primary-btn hover:opacity-90 active:opacity-75 mt-6"
      (click)="mintPrimary()"
    >
      Use Credit / Debit Card
    </button>
    <hr />
    <br />
    <div *ngIf="!showWeb3Payment">
      <button
        class="primary-btn hover:opacity-90 active:opacity-75"
        (click)="toggleWeb3()"
        disabled
      >
        Show Advanced Web3 Payment Options.
      </button>
    </div>

    <!-- ##### WEB3 PAYMENT SCREEN -->

    <div *ngIf="showWeb3Payment" class="text-center">
      <span class="raceFont3"
        >Advanced Web3 - USE USDC on POLYGON Blockchain</span
      >
      <div
        class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
      >
        <img
          src="../../../assets/images/polygon.png"
          width="35"
          class="text-center"
        />
      </div>

      <p>Web3 requires 2 transactions.</p>
      <br />
      <br />

      <div *ngIf="mintFee <= allowedAmount || isAdmin">
        <b>Step 1 of 2. COMPLETE. Approved USDC Spend </b>
      </div>

      <button
        class="warning-btn hover:opacity-90 active:opacity-75 bg-surface_blue"
        (click)="approveToken()"
        *ngIf="mintFee > allowedAmount && !isAdmin"
      >
        Step 1 of 2. Push to Approve USDC Spend
      </button>

      <p class="text-sm" *ngIf="mintFee > allowedAmount && !isAdmin">
        <i
          >(Approval is for the package amount, thus in case of multiple
          ServiceRecords purchases, each purchase requires a separate
          approval).</i
        >
      </p>

      <br />
      <div
        class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
      >
        <img
          src="../../../assets/images/usdc.svg"
          width="35"
          class="text-center"
        />
      </div>
      <br />
      <br />

      <!-- this button doesn't do anything on purpose -->
      <button
        class="disabled-btn hover:opacity-90 active:opacity-75"
        *ngIf="mintFee > allowedAmount && !isAdmin"
      >
        Step 2 of 2. Waiting for approval.
      </button>

      <button
        class="success-btn hover:opacity-90 active:opacity-75"
        (click)="closeModal('select-upgrade-mode-modal'); updateVin()"
        *ngIf="mintFee <= allowedAmount || isAdmin"
      >
        Step 2 of 2. Push to Create ServiceRecords
      </button>

      <br />
      <div *ngIf="showWeb3Payment">
        <br />
        <br />
        <button
          class="primary-btn hover:opacity-90 active:opacity-75"
          (click)="toggleWeb3()"
        >
          Hide Advanced Web3 Payment Options.
        </button>
      </div>
    </div>
    <!-- ## END OF WEB3 PAYMENT  -->
  </div>
  <!-- ## END OF SELECT MINT/PAYMENT  -->
</jw-modal>

<jw-modal id="stripe-checkout-modal">
  <div
    class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
  >
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('stripe-checkout-modal')"
    ></i>
    <form
      #checkout="ngForm"
      (ngSubmit)="onSubmit()"
      class="checkout py-4 px-2 md:px-8"
    >
      <div class="form-row">
        <label for="card-info" class="text-xl font-semibold">Card Info</label>
        <div id="card-info" class="mt-5" style="width: 200px" #cardInfo></div>
        <div
          id="card-errors"
          role="alert"
          class="mt-1 text-sm text-red-800"
          *ngIf="stripeError"
        >
          {{ stripeError }}
        </div>
      </div>
      <div class="flex justify-center">
        <button
          type="submit"
          class="writeButton bg-redLeather mt-8"
          *ngIf="!stripeProcessing"
        >
          Pay ${{ mintFee }}
        </button>
        <button class="writeButton bg-redLeather mt-8" *ngIf="stripeProcessing">
          Processing...
        </button>
      </div>
    </form>
  </div>
</jw-modal>

<jw-modal id="delete-confirm-modal">
  <div
    class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
  >
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('delete-confirm-modal')"
    ></i>
    <form
      #checkout="ngForm"
      (ngSubmit)="deleteVinTracker()"
      class="checkout py-4 px-2 md:px-8"
    >
      <div class="form-row">
        <label for="card-info" class="text-xl font-semibold"
          >Confirm deletion</label
        >
      </div>
      <div class="flex justify-center flex-col">
        <button type="submit" class="writeButton bg-redLeather mt-8">
          Confirm
        </button>
        <button
          class="writeButton bg-redLeather mt-8"
          (click)="closeModal('delete-confirm-modal')"
        >
          Cancel
        </button>
      </div>
    </form>
  </div>
</jw-modal>
