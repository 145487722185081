<div class="profile section__padding">
  <div class="profile-top">
    <div class="profile-banner">
      <div
        style="
          background: url(../../../assets/images/carbanner2.png) 50% / cover
            border-box padding-box;
        "
      ></div>
    </div>
    <div class="profile-pic">
      <img
        src="../../../assets/images/caratar.jpeg"
        alt="garage"
        width="120"
        height="120"
        class="rounded-full"
      />
      <h3
        *ngIf="
          !profileAddress.startsWith('0x') ||
          profileAddress?.toLowerCase() === walletAddress?.toLowerCase()
        "
        class="mb-1"
      >
        {{ getUserName() }}
      </h3>
      <h3 *ngIf="profileAddress.startsWith('0x')">
        {{ profileAddress.slice(0, 6) }}...{{
          profileAddress.slice(profileAddress.length - 6)
        }}
      </h3>
    </div>
  </div>
  <div
    class="profile-bottom xl:w-[1156px] mx-auto lg:w-[900px] w-full relative"
  >
    <div class="mt-20 px-6 md:px-0" *ngIf="!loading && list.length > 0">
      <h1 class="raceFont1 text-center text-4xl">ServiceRecords</h1>
      <ngx-masonry
        class="w-full mt-4"
        [ordered]="true"
        [updateLayout]="updateMasonryLayout"
      >
        <div
          class="md:mr-5 mb-3 md:mb-5 w-full md:w-[30%]"
          ngxMasonryItem
          *ngFor="let item of list"
        >
          <app-single-item-card [singleItem]="item"></app-single-item-card>
        </div>
      </ngx-masonry>
      <!-- <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mt-4">
        <div *ngFor="let item of list">
          <app-single-item-card [singleItem]="item"></app-single-item-card>
        </div>
      </div> -->
    </div>
    <div class="mt-20 px-6 md:px-0" *ngIf="!loading && list.length === 0">
      <h1 class="raceFont1 text-center text-4xl">NO ServiceRecords...</h1>
    </div>
    <div
      class="flex flex-col items-center justify-center rounded-lg z-10 mt-20"
      *ngIf="loading"
    >
      <app-vin-spinner></app-vin-spinner>
      <div class="mt-2 text-2xl font-bold">Opening Garage...</div>
    </div>
  </div>
</div>
