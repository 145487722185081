<div
  class="pricingTable 2xl:w-[1440px] xl:w-[1156px] mx-auto lg:w-[900px] w-full"
>
  <div class="h1 afont"><b> FAQ </b></div>

  <br />

  <h2 class="afont text-2xl font-bold">What exactly is a ServiceRecords?</h2>

  <h3 class="afont">
    A ServiceRecords is your individual car's digital showroom with a data
    vault. It's tied to your either a vehicle's VIN number (the 17-character
    code found on the registration card, insurance card, your engine, etc.) or a
    photograph and it is stored permanently and securely on the blockchain.
    <br />

    As a result, you can keep your ride's history organized and updated as it
    evolves with exceptional photos & videos, warranty & service records,
    ownership & legal records, external records such as CARFAX&#174;, and other
    important documents. This will come in handy not only for the pleasure of
    having a showroom with a digital vault but also when servicing and selling
    the car.
  </h3>
  <br />

  <h2 class="afont text-2xl font-bold">Is ServiceRecords an NFT?</h2>
  <h3 class="afont">
    Tehcnially yes. But, the underlying blockchain is just the technology we are
    using under the hood and it is not inteded for speculative purposes.
    Instagram is calling them digital collectibles and we are taking it one step
    further to include digital storage and vehicle's life history. We do all the
    work and you receive the end-product: The ServiceRecords. You can purchase
    it with a regular credit/debit card.
  </h3>
  <br />

  <h2 class="afont text-2xl font-bold">What can I do with a ServiceRecords?</h2>
  <h3 class="afont">
    Well, first you should probably certify it. This is where you provide proof
    of ownership in the form of title or car registration, a driver's license +
    selfie :). Our dealer services team will verify the ownership and certify
    the ServiceRecords. The main image will be updated with a "CERTIFIED" badge,
    and can be used as a powerful marketing tool.
    <br />

    You can update your ServiceRecords every time you get it serviced or install
    modifications and upgrades. The team doing the work will appreciate having
    records on hand of previous services or work being done as well as
    documenting the results of their craftmanship.
    <br />

    An exceptional photo or video shoot, before and after media, and commentary
    will elegantly document the life of your ride.
    <br />

    For the metaverse trailblazers - ServiceRecords lives on the blockchain as a
    digital collectible and is thus metaverse compatible and can participate in
    the technology evolution of the internet and social networks. You can use it
    as an avatar on Instagram!
    <br />

    You can also create collectibles and mint multiple ServiceRecords for your
    collection or others'.
    <br />

    They also make great gifts to those car enthusiasts among your friends and
    family.
    <br />
  </h3>
  <br />

  <h2 class="afont text-2xl font-bold">Why are there different packages?</h2>

  <h3 class="afont">
    Each package is suited for different needs.
    <br />
    &nbsp; <br />
    Our most affordable ServiceRecords package is recommended for the car
    enthusiast who wants to showcase their car digitally and potentially mint
    multiple ServiceRecords for their collection to document their car’s
    history. For $5 your car's unique ServiceRecords will live on the blockchain
    along with up to 6 media files and a description. It does not contain a data
    vault for records, and you may request to optionally certify it for an
    additional $10.00. It makes a great addition and upgrade to your car.

    <button
      class="pricingTable-firstTable_table__getstart bg-e"
      [routerLink]="['/create/enthusiast']"
    >
      Get ServiceRecords Package
    </button>

    <br />
    &nbsp; <br />

    Our most popular ServiceRecords with Digital Storage for Service Records
    package includes your own storage vault that retains your service records,
    important files, and any other documents that are associated with your car's
    history. Optional certification is included and you may store up to 24 media
    files such as photo's and videos. This package is best suited for a
    collector who is trying to keep all records organized and in a secure
    location for easy transfer and access, while having a digital collectible of
    their car to showcase.

    <button
      class="pricingTable-firstTable_table__getstart bg-c"
      [routerLink]="['/create/collector']"
    >
      Get ServiceRecords with Digital Storage for Service Records Package
    </button>

    <br />
    <br />
    &nbsp; <br />

    The ServiceRecords Professional Package with CARFAX&#174; is most suited for
    an active collector who may use their ServiceRecords as a powerful marketing
    tool. It includes an ability to store and share up to 100 photo or video
    files, a secure data room, optional free certification, a CARFAX&#174;, and
    a physical collectible card or poster shipped to your address!

    <button
      class="pricingTable-firstTable_table__getstart bg-p"
      [routerLink]="['/create/professional']"
    >
      Get ServiceRecords Professional Package with CARFAX&#174;
    </button>

    <br />
    <br />
    &nbsp; <br />

    <br />
    We also offer the Dealer Service package which includes everything with the
    ability to buy/sell your car through an authorized dealer. This package is
    most suited for a buyer or seller on an online platform (bringatrailer,
    carsandbids, ebay), with an authorized dealer acting as the escrow agent to
    handle the transfer of money and title through the transfer of the
    ServiceRecords.
  </h3>

  <br />
  <h2 class="afont text-2xl font-bold">
    What are the dealer and concierge services you provide?
  </h2>
  <br />
  <h3 class="afont">
    We provide personalized dealer and concierge services in ensuring a
    hassle-free and accurate transaction between buyers and sellers of vehicles,
    that include ownership verification, CARFAX&#174; and other external record
    research, escrow, titling, and registration. Our network of partners and
    affiliates provides additional services such as vehicle shipping, storage,
    detailing, photo/video services as well as modifications and upgrades.
    Additonal services include custom uploads, organizing records, and other VIN
    management support. Contact us atinfo@ServiceRecords.com for more
    information.
  </h3>

  <br />

  <h2 class="afont text-2xl font-bold">
    How do I certify my ServiceRecords and how much does it cost?
  </h2>
  <div class="afont" style="line-height: 150%">
    To certify you ServiceRecords you need to provide us with proof of
    ownership. Our dealer services team uses the title or regirstation along
    with a driver's license + selfie :) to verify the ownership and certify your
    ServiceRecords. You should email us with a link to your ServiceRecords and
    request certification. It is a feature that is included in the Collector's
    and Professional packages. For Enthusiast package the certification is
    $10.00.

    <div>
      The main image will be updated with a "CERTIFIED" badge, and can be used
      as a powerful marketing tool.
    </div>

    <br />

    <h2 class="afont text-2xl font-bold">
      Will external digital collectible sites and marketplaces such as Instagram
      recognize my ServiceRecords?
    </h2>
    <h3 class="afont">
      <p>
        Yes. Instagram, Reddit and other Polygon compatible platforms will
        recognize your ServiceRecords! <br />
      </p>

      <h2 class="afont text-2xl font-bold">
        I don't have a blockchain wallet, how do I get one?
      </h2>
      <h3 class="afont" style="line-height: 150%">
        If you are new to digital collectibles and blockchain wallets, please
        make sure you understand the risks and best security practices. There is
        a popular community open-source wallet such as
        <a href="https://metamask.io/" target="_blank">Metamask </a>

        To single-click configure it for the Polygon blockchain, a popular
        option is
        <a href="https://chainlist.org/" target="_blank">Chainlist.org</a>
        Simply click "Connect Wallet" under Polygon Mainnet card.
      </h3>

      <br />

      <h2 class="afont text-2xl font-bold">
        I'm not ready to create a wallet, where does my ServiceRecords go after
        I bought it?
      </h2>

      <h3 class="afont">
        If you are not ready to create a wallet or want to later send it to
        someone else, you're in good hands. After your ServiceRecords is created
        it is held in our corporate omnibus wallet. Whenever you are ready to
        withdraw it - send us an email with a request and the order confirmation
        number to info@ServiceRecords.com
      </h3>

      <br />

      <!-- <h2 class = "afont text-2xl font-bold">I have a wallet but do not have any MATIC for gas fees. Can I pay by credit card instead and get my ServiceRecords delivered to my wallet directly?</h2>
<h3 class = "afont"> <p>Absolutely! During the checkout process simply select credit/debit card and delivery to your wallet address.
</h3>

<br> -->

      <h2 class="afont text-2xl font-bold">
        I have my wallet connected - where can I find my ServiceRecords ?
      </h2>
      <h3 class="afont">
        <p>
          They will be under the "GARAGE" menu choices or hamburger.
          <a
            href="https://polygonscan.com/token/0x2791bca1f2de4661ed88a30c99a7a9449aa84174"
            >USDC.</a
          >
        </p>
      </h3>

      <br />

      <h2 class="afont text-2xl font-bold">
        I am an experienced web3 user. Can I mint directly without using a
        credit or debit card?
      </h2>
      <h3 class="afont">
        <p>
          Yes. We are fully Web3 compatible and running on Polygon.
          <img src="../../../assets/images/polygon.png" width="35" /> You will
          need a little bit of MATIC for gas and
          <a
            href="https://polygonscan.com/token/0x2791bca1f2de4661ed88a30c99a7a9449aa84174"
            >USDC.</a
          >
        </p>
      </h3>

      <br />

      <h2 class="afont text-2xl font-bold">
        Is there an affiliate or partnership program for referring folks to get
        their ServiceRecords?
      </h2>
      <h3 class="afont">
        Yes. We work with respected leaders in the industry and create bespoke
        packages for their communities and audiences. We work with dealerships,
        car experts and personalities, automotive servicing companies such as
        pro-shops, storage providers, shippers, and automotive enthusiasts.
        <br />
        Contact us at info@vin-tracker.com for more information.
      </h3>
    </h3>
  </div>
</div>
