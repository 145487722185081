<div class="xl:w-[1156px] mx-auto lg:w-[900px] w-full pt-8 mt-8">
  <h1
    class="px-4 font-semibold text-4xl md:text-3xl my-12 lg:px-0 text-left default-font"
  >
    Create new ServiceRecords&trade;
  </h1>
  <div class="p-4 md:px-0 create relative">
    <div
      class="flex flex-col loadingBox absolute inset-0 items-center pt-32 rounded-lg z-10"
      *ngIf="loading"
    >
      <app-vin-spinner></app-vin-spinner>
      <div class="mt-2 text-2xl font-bold text-center px-2">
        Engine is Running, this could take a few minutes to upload to the
        blockchain
      </div>
    </div>
    <div class="create-container bg-">
      <div
        class="writeForm grid grid-cols-1 md:grid-cols-2 md:gap-8"
        autoComplete="off"
      >
        <div class="col-span-2 md:col-span-1">
          <div class="formGroup">
            <label>Vehicle Name</label>
            <input
              type="text"
              maxlength="100"
              placeholder="Vehicle Name (2023 Porsche 911 Targa 4 GTS)"
              autoFocus="{true}"
              required
              [(ngModel)]="name"
            />
          </div>
          <div class="formGroup">
            <label>Vehicle Description</label>
            <textarea
              type="text"
              maxlength="100"
              rows="1"
              placeholder="Decription of your vehicle. What makes it special."
              [(ngModel)]="description"
            ></textarea>
          </div>

          <!-- are you planning on selling this vehicle? Yes / No checkbox. If Yes show a price input field. Default as Yes-->
          <div class="formGroup">
            <div *ngIf="isForSale">
              <label>Your Price Terms</label>
              <input
                type="text"
                placeholder="For Example, $34,000 or Negotiable"
                required
                [(ngModel)]="price"
                (input)="new_price()"
              />
            </div>
            <br />
            <button
              class="button2"
              style="max-width: 25%"
              (click)="toggle_for_sale()"
            >
              {{ isForSale ? "Turn Off" : "Set Price Terms" }}
            </button>
          </div>

          <p class="upload-file">
            Please Upload Main Photo/Video for ServiceRecords Listing
          </p>
          <label
            *ngIf="!uploadData.nft_image"
            for="upload"
            class="upload-img-show"
          >
            <h3>JPG, PNG, GIF, SVG, WEBM, MP3, MP4. Max 100mb.</h3>
            <img
              src="../../../assets/images/carUpload.jpeg"
              class="cursor-pointer"
              alt="Please upload car photos or video for your ServiceRecords by pressing here."
              style="max-width: 50%; max-height: 50%; object-fit: contain"
            />
            <p>Drag & Drop File</p>
          </label>
          <input
            type="file"
            id="upload"
            (change)="onFileChanged($event)"
            #fileInput
            class="custom-file-input hidden"
            accept="image/*, video/*"
          />
          <div *ngIf="uploadData.nft_image" class="upload-img-show">
            <label for="upload" class="cursor-pointer">Upload Again</label>
            <img
              src="{{ nft_image_content }}"
              class="w-[500px] h-[150px] object-scale-down"
              *ngIf="!isVideo"
            />
            <video
              loop
              controlsList="nodownload"
              class="w-[500px] object-contain"
              controls
              *ngIf="isVideo"
              [src]="nft_image_content"
            >
              <source type="video/webm" [src]="nft_image_content" />
              <source type="video/mp4" [src]="nft_image_content" />
            </video>
          </div>
          <div class="formGroup" *ngIf="uploadData.nft_image">
            <label
              class="text-lg font-semibold cursor-pointer"
              *ngIf="additionalMediaCount > currentMediaCount"
              for="uploadAdditional"
              >+ Add More ({{ currentMediaCount }} /
              {{ additionalMediaCount }})</label
            >
            <label
              class="text-lg font-semibold text-gray-500"
              *ngIf="additionalMediaCount <= currentMediaCount"
              >Cannot add more for this pricing plan</label
            >
            <input
              type="file"
              id="uploadAdditional"
              (change)="addExtraFile($event)"
              multiple="multiple"
              #fileInput
              class="custom-file-input hidden"
              accept="image/*, video/*"
            />
            <div
              class="flex flex-row flex-wrap gap-4"
              *ngIf="currentMediaCount > 0"
            >
              <div
                *ngFor="let _extra of additionMedias; let i = index"
                class="additionalMedia relative"
              >
                <img
                  src="{{ _extra.content }}"
                  class="h-28 object-contain rounded-xl"
                  *ngIf="!_extra.isVideo"
                />
                <video
                  loop
                  controlsList="nodownload"
                  class="h-28 object-contain rounded-xl"
                  controls
                  *ngIf="_extra.isVideo"
                >
                  <source type="video/webm" [src]="_extra.content" />
                  <source type="video/mp4" [src]="_extra.content" />
                </video>
                <i
                  class="far fa-times-circle absolute -top-2 -right-2 bg-white rounded-full text-xl leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                  (click)="closeExtra(i)"
                ></i>
              </div>
            </div>
          </div>

          <div class="formGroup">
            <label>Links</label>
            <textarea
              type="text"
              rows="2"
              placeholder="Paste external links to your vehicle here."
              [(ngModel)]="links"
            ></textarea>
          </div>
          <div class="formGroup">
            <label>Email</label>
            <span class="vin-input" *ngIf="authService.isLogin">{{
              authService.curUser.email
            }}</span>
            <button
              class="vin-input"
              *ngIf="!authService.isLogin"
              (click)="openModal('login-modal')"
            >
              Login or Sign up to connect email
            </button>
          </div>

          <div class="hidden md:block">
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <input type="checkbox" class="hidden" checked />
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>

              <span class="select-none"
                >Allow email address notifications of comments and offers</span
              >
            </label>
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <!-- <input type="checkbox" class="hidden" checked /> -->
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>
              <!-- <span class="select-none">Allow Make Offers on ServiceRecords details page
              </span> -->
            </label>
            <label class="custom-label flex p-2">
              <div
                class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
              >
                <!-- <input type="checkbox" class="hidden" checked /> -->
                <svg
                  class="hidden w-4 h-4 text-purple pointer-events-none"
                  viewBox="0 0 172 172"
                >
                  <g
                    fill="none"
                    stroke-width="none"
                    stroke-miterlimit="10"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                  >
                    <path d="M0 172V0h172v172z" />
                    <path
                      d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                      fill="blue"
                      stroke-width="1"
                    />
                  </g>
                </svg>
              </div>
              <!-- <span class="select-none">Allow comments section on ServiceRecords details page
              </span> -->
            </label>
          </div>
        </div>
        <div class="col-span-2 md:col-span-1">
          <div>
            <div class="formGroup" id="details">
              <label>To Certify or Sell Your Vehicle</label>
              <div *ngIf="attributes">
                <!-- <button (click)="openModal('vehicle-detail-modal')"> -->
                <button class="button2" (click)="openAttributeForm()">
                  Set Vin, Price, Year, Make, Model
                </button>
              </div>
            </div>

            <div class="formGroup" autocomplete="off">
              <label>ServiceRecords Package</label>
              <ng-select
                [items]="planList"
                bindLabel="name"
                bindValue="id"
                [(ngModel)]="_pricePlan"
                (change)="changedPricePlan()"
              >
              </ng-select>
            </div>

            <div *ngIf="_pricePlan == 'enthusiast'">
              <label
                >Digital Storage Vault is unavailable with this package.</label
              >
            </div>

            <div *ngIf="_pricePlan !== 'enthusiast'">
              <div class="formGroup" id="vault">
                <div class="formGroup">
                  <label> Service Records</label>
                  <label
                    for="upload_service_record"
                    class="label-input cursor-pointer"
                    >Upload Files</label
                  >
                  <input
                    type="file"
                    (change)="onFileChanged($event, 'service_record')"
                    class="custom-file-input hidden"
                    id="upload_service_record"
                    #fileInput
                  />
                  <div class="flex flex-wrap gap-3">
                    <div
                      *ngFor="
                        let serviceRecord of uploadData.service_record;
                        let i = index
                      "
                      class="relative w-fit-content bg-surface_blue rounded-lg py-1 px-5"
                    >
                      <span class="text-white font-bold">{{
                        serviceRecord.type
                      }}</span>
                      <i
                        class="far fa-times-circle absolute -top-1 -right-1 bg-white rounded-full leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                        (click)="closeRecord(i, 'service_record')"
                      ></i>
                    </div>
                  </div>
                  <div>
                    (Service records, mileage logs, receipts of parts
                    purchased).
                  </div>
                </div>

                <div class="formGroup">
                  <label>Ownership & Registration</label>
                  <label
                    for="upload_ownership_record"
                    class="label-input cursor-pointer"
                    >Upload Files</label
                  >
                  <input
                    type="file"
                    (change)="onFileChanged($event, 'ownership')"
                    class="custom-file-input hidden"
                    id="upload_ownership_record"
                    #fileInput
                  />
                  <div class="flex flex-wrap gap-3">
                    <div
                      *ngFor="
                        let ownershipItem of uploadData.ownership;
                        let i = index
                      "
                      class="relative w-fit-content bg-surface_blue rounded-lg py-1 px-5"
                    >
                      <span class="text-white font-bold">{{
                        ownershipItem.type
                      }}</span>
                      <i
                        class="far fa-times-circle absolute -top-1 -right-1 bg-white rounded-full leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                        (click)="closeRecord(i, 'ownership')"
                      ></i>
                    </div>
                  </div>
                  <div>
                    (Title, registration, bills of sale, other provenance
                    information).
                  </div>
                </div>

                <label>Other Documents</label>
                <label
                  for="upload_ext_record"
                  class="label-input cursor-pointer"
                  >Upload Files</label
                >
                <input
                  type="file"
                  (change)="onFileChanged($event, 'ext_record')"
                  class="custom-file-input hidden"
                  id="upload_ext_record"
                  #fileInput
                />
                <div class="flex flex-wrap gap-3">
                  <div
                    *ngFor="
                      let extRecord of uploadData.ext_record;
                      let i = index
                    "
                    class="relative w-fit-content bg-surface_blue rounded-lg py-1 px-5"
                  >
                    <span class="text-white font-bold">{{
                      extRecord.type
                    }}</span>
                    <i
                      class="far fa-times-circle absolute -top-1 -right-1 bg-white rounded-full leading-4 hover:opacity-90 active:opacity-75 cursor-pointer"
                      (click)="closeRecord(i, 'ext_record')"
                    ></i>
                  </div>
                </div>
                <div>
                  (Build sheets, CARFAX&#174; reports, original sales brochures,
                  and other important docs).
                </div>
              </div>

              <div class="formGroup">
                <label>Set Vault Password</label>
                <input
                  type="password"
                  min="1"
                  placeholder="Enter Password"
                  [(ngModel)]="password"
                />
                <div class="mt-5">
                  (Use a different password for the vault so you may share
                  access to view its contents).
                </div>
              </div>
            </div>

            <!-- <div *ngIf="_pricePlan == 'enthusiast'">

              <div class="formGroup">
                <label>Referral Code</label>
                <input type="text" placeholder="Enter optional referral code" [(ngModel)]="refCode" (input)="changedRefCode()" />

              </div>

              <div *ngIf="refCodeAccepted" class="mt-5 font-bold">
                Referral Code Accepted. <br />
                Your discounted price is now: {{ mintFee }}.
              </div>

              <div class="flex items-center justify-end col-span-2">
                <button type="button" (click)="openMintModal()" class="writeButton bg-redLeather">
                  Create ServiceRecords
                </button>
              </div>
            </div> -->

            <div class="block md:hidden">
              <label class="custom-label flex p-2">
                <div
                  class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
                >
                  <input type="checkbox" class="hidden" checked />
                  <svg
                    class="hidden w-4 h-4 text-purple pointer-events-none"
                    viewBox="0 0 172 172"
                  >
                    <g
                      fill="none"
                      stroke-width="none"
                      stroke-miterlimit="10"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <path d="M0 172V0h172v172z" />
                      <path
                        d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                        fill="blue"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                </div>
                <span class="select-none"
                  >Allow email notifications of comments and offers</span
                >
              </label>
              <label class="custom-label flex p-2">
                <div
                  class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
                >
                  <!-- <input type="checkbox" class="hidden" checked /> -->
                  <svg
                    class="hidden w-4 h-4 text-purple pointer-events-none"
                    viewBox="0 0 172 172"
                  >
                    <g
                      fill="none"
                      stroke-width="none"
                      stroke-miterlimit="10"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <path d="M0 172V0h172v172z" />
                      <path
                        d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                        fill="blue"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                </div>
                <span class="select-none"
                  >Allow Make Offers on VIN Details</span
                >
              </label>
              <label class="custom-label flex p-2">
                <div
                  class="bg-white shadow w-6 h-6 p-1 flex justify-center items-center mr-2"
                >
                  <!-- <input type="checkbox" class="hidden" checked /> -->
                  <svg
                    class="hidden w-4 h-4 text-purple pointer-events-none"
                    viewBox="0 0 172 172"
                  >
                    <g
                      fill="none"
                      stroke-width="none"
                      stroke-miterlimit="10"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <path d="M0 172V0h172v172z" />
                      <path
                        d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
                        fill="blue"
                        stroke-width="1"
                      />
                    </g>
                  </svg>
                </div>
                <span class="select-none"
                  >Allow comments on ServiceRecords details page</span
                >
              </label>
            </div>
            <div class="formGroup" *ngIf="isAdmin">
              <label>Receiver Wallet</label>
              <input
                class="border border-dashed border-black px-4 py-2 rounded-lg w-100"
                type="text"
                placeholder="Receiver Wallet"
                autoFocus="{true}"
                required
                [(ngModel)]="_receiver"
              />
            </div>
          </div>

          <!-- <div *ngIf="_pricePlan != 'enthusiast'">

            <div class="formGroup">
              <label>Referral Code</label>
              <input type="text" placeholder="Enter optional referral code" [(ngModel)]="refCode"
                (change)="changedRefCode()" />
            </div>

            <div *ngIf="refCodeAccepted" class="mt-5 font-bold">
              Referral Code Accepted. <br />
              Your discounted price is now: {{ mintFee }}.
            </div>

            <div class="flex items-center justify-end col-span-2">
              <button type="button" (click)="openMintModal()" class="writeButton bg-redLeather">
                Create ServiceRecords
              </button>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Vehicle Details -->

<jw-modal id="vehicle-detail-modal">
  <div style="overflow: auto; height: calc(100vh - 50px); max-height: 100%">
    <div
      class="font-semibold mr-2 cursor-pointer"
      (click)="closeModal('vehicle-detail-modal')"
    >
      X
    </div>
    <form
      (ngSubmit)="setTraits()"
      [formGroup]="addAttributesForm"
      class="flex items-center justify-center w-full flex-col min-h-[27vh]"
    >
      <h2 class="text-2xl font-semibold mb-4">Vehicle Details</h2>

      <div class="formGroup">
        <label class="text-center">ENTER 17-CHARACTER VIN #</label>
        <input
          type="text"
          name="vinSn"
          placeholder="17-character VIN is required."
          [(ngModel)]="vinSn"
          (change)="decodeMake($event)"
        />
        <div>{{ decodedMake }}</div>
      </div>

      <div *ngIf="vinSn.length == 17">
        <div class="grid grid-cols-1 md:grid-cols-2 px-10 md:px-0">
          <div
            class="attributes flex items-center justify-center"
            formArrayName="attributes"
            *ngFor="let attribute of attributes.controls; let i = index"
          >
            <div [formGroupName]="i" class="mb-4">
              <div
                class="font-semibold mr-2 cursor-pointer"
                (click)="removeTrait(i)"
              >
                X
              </div>
              <div class="formGroup mr-2">
                <input
                  type="text"
                  placeholder="Attribute Type"
                  class="font-semibold mb-2"
                  formControlName="trait_type"
                  id="{{ 'trait_type' + i }}"
                  data-testid="Input"
                  [value]="attribute.value.trait_type"
                />
                <input
                  type="text"
                  placeholder="Input {{ attribute.value.trait_type }}"
                  formControlName="value"
                  id="{{ 'value' + i }}"
                  data-testid="Input"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <button class="writeButton bg-vin-blue mr-2" (click)="addTrait()">
            Add More
          </button>
          <button
            type="submit"
            (click)="closeModal('vehicle-detail-modal')"
            class="writeButton bg-gray-500"
          >
            Save Details
          </button>
        </div>
      </div>

      <div
        *ngIf="vinSn.length != 17"
        style="pointer-events: none; opacity: 0.5"
      >
        <div class="grid grid-cols-1 md:grid-cols-2 px-10 md:px-0">
          <div
            class="attributes flex items-center justify-center"
            formArrayName="attributes"
            *ngFor="let attribute of attributes.controls; let i = index"
          >
            <div [formGroupName]="i" class="mb-4">
              <div
                class="font-semibold mr-2 cursor-pointer"
                (click)="removeTrait(i)"
              >
                X
              </div>
              <div class="formGroup mr-2">
                <input
                  type="text"
                  placeholder="Attribute Type"
                  class="font-semibold mb-2"
                  formControlName="trait_type"
                  id="{{ 'trait_type' + i }}"
                  data-testid="Input"
                  [value]="attribute.value.trait_type"
                />
                <input
                  type="text"
                  placeholder="Input {{ attribute.value.trait_type }}"
                  formControlName="value"
                  id="{{ 'value' + i }}"
                  data-testid="Input"
                  value=""
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <button class="writeButton bg-vin-blue mr-2" (click)="addTrait()">
            Add More
          </button>
          <button
            type="submit"
            (click)="closeModal('vehicle-detail-modal')"
            class="writeButton bg-gray-500"
          >
            Save Details
          </button>
        </div>
      </div>
    </form>
  </div>
</jw-modal>

<!-- Stripe Checkout -->
<jw-modal id="stripe-checkout-modal">
  <div
    class="relative flex flex-col items-center justify-between md:gap-6 pt-6 afont"
  >
    <!-- <a href = "https://stripe.com/docs/security"> <img src = "https://thumbs.bfldr.com/at/g65qkq94m43qc3c9fqnhh3m?expiry=1688013495&fit=bounds&height=800&sig=NGRlZTQ0ZmU3YTc3NzdlMjEyZGIzZWZmZWNhMjE0NGMxOTFhMDYzNA%3D%3D&width=1100"></a> -->
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('stripe-checkout-modal')"
    ></i>
    <form
      #checkout="ngForm"
      (ngSubmit)="onSubmit()"
      class="checkout py-4 px-2 md:px-8"
    >
      <div class="form-row">
        <label for="card-info" class="text-xl font-semibold">Card Info</label>
        <div id="card-info" class="mt-5" style="width: 200px" #cardInfo></div>
        <div
          id="card-errors"
          role="alert"
          class="mt-1 text-sm text-red-800"
          *ngIf="stripeError"
        >
          {{ stripeError }}
        </div>
      </div>
      <div class="flex justify-center">
        <button
          type="submit"
          class="writeButton bg-redLeather mt-8"
          *ngIf="!stripeProcessing"
        >
          Pay ${{ mintFee }}
        </button>
        <button class="writeButton bg-redLeather mt-8" *ngIf="stripeProcessing">
          Processing...
        </button>
      </div>
    </form>
  </div>
</jw-modal>

<!-- Select Mint Way -->
<jw-modal id="select-mint-mode-modal">
  <div class="relative flex flex-col items-center justify-between pt-6">
    <i
      class="fas fa-times-circle absolute right-0 top-0 cursor-pointer"
      (click)="closeModal('select-mint-mode-modal')"
    ></i>
    <img src="../../../assets/images/cc.png" width="35" />
    <button
      class="primary-btn hover:opacity-90 active:opacity-75 mt-6"
      (click)="mintPrimary()"
    >
      Use Credit / Debit Card
    </button>
    <hr />
    <br />
    <div *ngIf="!showWeb3Payment">
      <button
        class="primary-btn hover:opacity-90 active:opacity-75"
        (click)="toggleWeb3()"
      >
        Show Advanced Web3 Payment Options.
      </button>
    </div>

    <!-- ##### WEB3 PAYMENT SCREEN -->

    <div *ngIf="showWeb3Payment" class="text-center">
      <span class="raceFont3"
        >Advanced Web3 - USE USDC on POLYGON Blockchain</span
      >
      <div
        class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
      >
        <img
          src="../../../assets/images/polygon.png"
          width="35"
          class="text-center"
        />
      </div>

      <p>Web3 requires 2 transactions.</p>
      <br />
      <br />

      <div *ngIf="mintFee <= allowedAmount || isAdmin">
        <b>Step 1 of 2. COMPLETE. Approved USDC Spend </b>
      </div>

      <button
        class="warning-btn hover:opacity-90 active:opacity-75 bg-surface_blue"
        (click)="approveToken()"
        *ngIf="mintFee > allowedAmount && !isAdmin"
      >
        Step 1 of 2. Push to Approve USDC Spend
      </button>

      <p class="text-sm" *ngIf="mintFee > allowedAmount && !isAdmin">
        <i
          >(Approval is for the package amount, thus in case of multiple
          ServiceRecords purchases, each purchase requires a separate
          approval).</i
        >
      </p>

      <br />
      <div
        class="relative flex flex-col items-center justify-between md:gap-6 pt-6"
      >
        <img
          src="../../../assets/images/usdc.svg"
          width="35"
          class="text-center"
        />
      </div>
      <br />
      <br />

      <!-- this button doesn't do anything on purpose -->
      <button
        class="disabled-btn hover:opacity-90 active:opacity-75"
        *ngIf="mintFee > allowedAmount && !isAdmin"
      >
        Step 2 of 2. Waiting for approval.
      </button>

      <button
        class="success-btn hover:opacity-90 active:opacity-75"
        (click)="closeModal('select-mint-mode-modal'); mintVin()"
        *ngIf="mintFee <= allowedAmount || isAdmin"
      >
        Step 2 of 2. Push to Create ServiceRecords
      </button>

      <br />
      <div *ngIf="showWeb3Payment">
        <br />
        <br />
        <button
          class="primary-btn hover:opacity-90 active:opacity-75"
          (click)="toggleWeb3()"
        >
          Hide Advanced Web3 Payment Options.
        </button>
      </div>
    </div>
    <!-- ## END OF WEB3 PAYMENT  -->
  </div>
  <!-- ## END OF SELECT MINT/PAYMENT  -->
</jw-modal>

<!-- Upload External -->
<jw-modal id="upload-external-modal">
  <button
    class="relative top-2 right-2 text-2xl text-black float-right"
    (click)="closeModal('upload-external-modal')"
  >
    <i class="fas fa-times"></i>
  </button>
  <div class="flex flex-col items-center">
    <h2 class="text-2xl font-semibold text-center mb-6">
      Upload {{ getUploadTypeText() }}
    </h2>
    <!-- <ng-select
      [items]="docType[uploadType]"
      bindLabel="name"
      bindValue="name"
      placeholder="Choose document type"
      [(ngModel)]="tmpDataType"
      class="w-full"
    >
    </ng-select> -->
    <input
      type="text"
      placeholder="Choose doc type below or type in your own label"
      required
      [(ngModel)]="tmpDataType"
      class="vin-input"
    />
    <div class="mt-4 flex items-center gap-3 w-full flex-wrap">
      <button
        *ngFor="let item of docType[uploadType]"
        class="w-fit-content bg-surface_blue rounded-lg py-1 px-5 text-white font-semibold"
        (click)="tmpDataType = item.name"
      >
        {{ item.name }}
      </button>
    </div>
    <div class="vin-input mt-4">{{ tmpUploadFile?.name }}</div>
    <button class="writeButton bg-vin-blue mt-6" (click)="confirmUpload()">
      Upload
    </button>
  </div>
</jw-modal>
