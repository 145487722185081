import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/components/_modal';
import { VinNFTService } from 'src/app/services/vinNFT.service';
import { WalletService } from 'src/app/services/wallet.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
} from '@angular/forms';
import { MathService } from 'src/app/services/math.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { VindataserviceService } from 'src/app/services/vindataservice.service';
import { AuthService } from 'src/app/services/auth.service';
import mergeImages from '../../utils/myMergeImages';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { e } from 'mathjs';
import { env } from 'process';
import { CookieService } from 'ngx-cookie-service';
import { error, log } from 'console';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit, AfterViewInit {
  nft_image_content: any;
  uploadData: any = {
    nft_image: null,
    ext_record: [],
    service_record: [],
    ownership: [],
  };
  ipfsExtension: any = {
    nft_image: '',
    ext_record: [],
    service_record: [],
    ownership: [],
  };
  loading: boolean = false;
  showWeb3Payment: boolean = false;

  name = '';
  description = '';
  links: string = '';
  isVideo: boolean = false;
  traitsArray: any;
  addAttributesForm: any;
  vinSn: string = '';
  password: string = '';
  mintFee: number = 0;
  allowedAmount: number = 0;
  isAdmin: boolean = false;
  _minter: string | null = null;
  _receiver: string = '';

  //  ################## PRICING PLANS
  _pricePlan: string = 'enthusiast';
  priceEnthusiast = environment.mintFee.enthusiast;
  priceCollector = environment.mintFee.collector;
  priceProfessional = environment.mintFee.professional;
  priceFree = environment.mintFee.free;

  planList: any[];

  // ###### DEFAULTS
  refCode: string = '';
  refCodeAccepted = false;
  additionalMediaCount: number = 25;
  currentMediaCount: number = 0;
  additionMedias: any[] = [];
  additionMediaFiles: any[] = [];
  mintWay: string = '';
  img_ipfs_uri: string;
  token_ipfs_uri: string;
  token_metadata: string;
  token_private_ipfs_uri: string = '';
  token_private_metadata: string = '';
  isForSale: boolean = true;
  price: string = '';

  wyre_orderId: string;
  wyre_transferId: string;
  decodedMake: string;

  uploadType: string = 'ext_record';
  tmpUploadFile: File;
  tmpDataType: string;

  docType: any = {
    ext_record: [
      {
        name: 'Build sheet',
      },
      {
        name: 'Warranty card',
      },
      {
        name: 'CARFAX report',
      },
      {
        name: 'Sales brochure',
      },
    ],
    service_record: [
      {
        name: 'Service record',
      },
      {
        name: 'Parts receipt',
      },
      {
        name: 'Mileage log',
      },
    ],
    ownership: [
      {
        name: 'Title',
      },
      {
        name: 'Registration',
      },
      {
        name: 'Bill of Sale',
      },
    ],
  };
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  stripeError: any;
  stripe: any;
  card: any;
  cardHandler = this.onStripeChange.bind(this);
  stripeProcessing: boolean = false;

  get priceTraitControl(): FormGroup | null {
    const index = this.attributes.controls.findIndex(
      (control) => control.get('trait_type')?.value === 'Price'
    );
    if (index !== -1) {
      return this.attributes.at(index) as FormGroup;
    }
    return null;
  }
  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private wallet: WalletService,
    private vinnft: VinNFTService,
    private readonly math: MathService,
    private readonly toastr: ToastrService,
    private readonly route: ActivatedRoute,
    private readonly offchain: OffchainService,
    private readonly vinData: VindataserviceService,
    public readonly authService: AuthService,
    private stripeService: AngularStripeService,
    private router: Router,
    private cookieService: CookieService
  ) {}
  async ngOnInit() {
    this.route.params.subscribe((queryParams) => {
      this.loadPlans();
      console.log(queryParams, 'queryParams');
      this._pricePlan = queryParams['plan'];
      this.changedPricePlan();

      this.refCode = this.cookieService.get('referrer_discount_code');
      console.log('RefCode =', this.refCode);

      // if (queryParams['refCode']) {
      //   this.refCode = queryParams['refCode'];
      //   this.changedRefCode();
      // }
    });
    this.addAttributesForm = this.fb.group({
      // attributes: this.fb.array([this.initialValue], Validators.required),
      attributes: this.fb.array(
        [
          this.createTraitInput('Make', ''),
          this.createTraitInput('Model', ''),
          this.createTraitInput('Year', ''),
          this.createTraitInput('Motor Transmission', ''),
          this.createTraitInput('Color Exterior', ''),
          this.createTraitInput('Price', ''),
        ],
        Validators.required
      ),
    });

    this.setReceiver();
  }

  ngAfterViewInit(): void {
    // const container = document.getElementById("scroll-container");
    // if(!container) {
    //   console.log("Error loading component ng182");
    //   return;}
    // const windowHeight = window.innerHeight;
    // const containerHeight = windowHeight - (windowHeight * 0.1); // subtract 10%
    // container.style.height = containerHeight + "px";
  }

  onStripeChange(error: any) {
    console.log(error, this, 'stripe change erorr');
    if (error.error) {
      this.stripeError = error.error.message;
    } else {
      this.stripeError = null;
    }
  }

  async openAttributeForm() {
    this.openModal('vehicle-detail-modal');
  }

  async processStripePayment(): Promise<any> {
    this.stripeProcessing = true;
    this.stripeError = null;
    try {
      const intent = await this.offchain.stripeCreateIntent(this.mintFee);
      const result = await this.stripe.confirmCardPayment(
        intent.client_secret,
        {
          payment_method: {
            card: this.card,
          },
        }
      );
      console.log(result, 'stripe confirm');
      if (result.error) {
        this.stripeError = result.error.message;
        throw Error;
      }
      return result;
    } catch (error) {
      console.log(error, 'stripe confirm error');
      this.toastr.error('Stripe Error');
      throw error;
    } finally {
      this.stripeProcessing = false;
    }
  }

  async onSubmit() {
    try {
      console.log(this._pricePlan, this.mintFee);
      var pricePlan = this._pricePlan;
      var id = this.generateGUID();
      if (pricePlan == 'free' || this.mintFee == 0) {
        pricePlan = 'enthusiast';
      } else {
        const result = await this.processStripePayment();
        if (result.paymentIntent?.status === 'succeeded') {
          this.toastr.success(
            'Success! Your payment have been processed successfully! Please wait while ServiceRecords information is synchronised.'
          );
          this.closeModal('stripe-checkout-modal');
          id = result.paymentIntent.id;
        }
      }
      window.scrollTo(0, 0);
      this.loading = true;
      console.log('Uploading to Ipfs...');
      await this.uploadToIpfs();
      console.log('Uploading to Ipfs...Done');
      const createdNft = await this.offchain.primaryMint({
        name: this.name,
        image: this.img_ipfs_uri,
        vin: this.vinSn,
        tokenUri: this.token_ipfs_uri,
        tokenPrivateUri: this.token_private_ipfs_uri,
        pkg: pricePlan,
        promoCode: this.refCode,
        id: id,
      });
      await this.offchain.createNft(
        createdNft.tokenId,
        // this.authService.curUser.email,
        this.authService.curUser._id,
        this.token_ipfs_uri,
        this.token_metadata,
        this.token_private_metadata,
        this.refCodeAccepted ? this.refCode : '',
        this.authService.curUser.email,
        this.password
      );
      this.toastr.success('Successfully minted');
      this.loading = false;
      this.router.navigate(['/item/' + createdNft.tokenId]);
    } catch (error) {
      console.log('Error was happened -->', error);
      this.loading = false;
      this.toastr.error(
        'The error was happened while uploading media files.',
        'Error'
      );
      // Handle any other errors here
    }
  }

  async setReceiver() {
    try {
      this._minter = await this.wallet.getAccount();
      console.log(this._minter, 'minter wallet');
      if (this._minter) {
        this._receiver = this._minter;
        this.loadFeeTokenData();
        const feeWallet = await this.vinnft.getFeeWallet();
        console.log(feeWallet, 'feeWallet');
        if (feeWallet.toLowerCase() === this._minter.toLowerCase())
          this.isAdmin = true;
      }
    } catch (error) {
      console.log(error, 'setReceiver');
    }
  }

  async loadFeeTokenData() {
    if (this.mintFee === 0 && this._pricePlan != 'free')
      await this.getMintFeeByPlan();
    this.allowedAmount = this.math.toHumanValue(
      await this.vinnft.allowedToken()
    );
  }

  loadDefaultPrices() {
    this.priceEnthusiast = environment.mintFee.enthusiast;
    this.priceCollector = environment.mintFee.collector;
    this.priceProfessional = environment.mintFee.professional;
    this.priceFree = environment.mintFee.free;
  }

  loadPlans() {
    this.planList = [
      {
        name: 'ServiceRecords $' + this.priceEnthusiast.toString(),
        id: 'enthusiast',
        addtionalCnt: 25,
      },
      // {
      //   name:
      //     'ServiceRecords + Digital Storage $' + this.priceCollector.toString(),
      //   id: 'collector',
      //   addtionalCnt: 23,
      // },
      // {
      //   name: 'ServiceRecords Free ',
      //   id: 'free',
      //   addtionalCnt: 5,
      // },
      {
        name: 'ServiceRecords Pro $' + this.priceProfessional.toString(),
        id: 'professional',
        addtionalCnt: 250,
      },
    ];
  }

  changedPricePlan() {
    this.loadDefaultPrices();
    this.planList.map((item) => {
      if (item.id === this._pricePlan)
        this.additionalMediaCount = item.addtionalCnt;
    });
    this.additionMedias.splice(this.additionalMediaCount, 100);
    this.additionMediaFiles.splice(this.additionalMediaCount, 100);
    if (this.currentMediaCount > this.additionalMediaCount)
      this.currentMediaCount = this.additionalMediaCount;
    this.password = '';
    this.getMintFeeByPlan();
    if (this.refCode != '') {
      this.changedRefCode();
    }
  }

  changedRefCode() {
    this.loadDefaultPrices();
    let i = environment.refCodes.indexOf(this.refCode);
    if (i >= 0) {
      this.priceEnthusiast =
        Math.round(
          Math.max(0, this.priceEnthusiast - environment.refCodeValues[i]) * 100
        ) / 100;
      this.priceCollector =
        Math.round(
          Math.max(0, this.priceCollector - environment.refCodeValues[i] * 2) *
            100
        ) / 100;
      this.priceProfessional =
        Math.round(
          Math.max(
            0,
            this.priceProfessional - environment.refCodeValues[i] * 3
          ) * 100
        ) / 100;
      console.log('RefCode Accepted');
      this.refCodeAccepted = true;
      this.getMintFeeByPlan();
      this.loadPlans();
    } else {
      console.log('RefCode Invalid');
      this.refCodeAccepted = false;
      this.getMintFeeByPlan();
      this.loadPlans();
    }
  }

  async getMintFeeByPlan() {
    // this.mintFee = this.vinnft.getMintFee(this._pricePlan);

    if (this._pricePlan == 'enthusiast') {
      this.mintFee = this.priceEnthusiast;
    } else if (this._pricePlan == 'collector') {
      this.mintFee = this.priceCollector;
    } else if (this._pricePlan == 'professional') {
      this.mintFee = this.priceProfessional;
    } else if (this._pricePlan == 'free') {
      this.mintFee = 0;
    }

    console.log(this._pricePlan, this.mintFee);
  }

  validateMint(): boolean {
    if (!this.uploadData.nft_image) {
      this.toastr.error('Please upload main image/video.', 'Error');
      return false;
    }
    if (!this.name || this.name === '') {
      this.toastr.error('Please enter the name for the vehicle', 'Error');
      return false;
    }
    if (!this.description || this.description === '') {
      this.toastr.error('Please enter the description of the vehicle', 'Error');
      return false;
    }
    // if (!this.vinSn || this.vinSn === '' || this.vinSn.length != 17) {
    //   this.toastr.error('Please enter the 17-character VIN', 'Error');
    //   return false;
    // }
    // #### NO MORE REQUIREMENT FOR HAVING AT LEAST ONE ATTRIBUTE
    // if (this.addAttributesForm.status !== 'VALID') {
    //   this.toastr.error('Please fill-in the vehicle details.', 'Error');
    //   return false;
    // }
    return true;
  }

  async decodeMake(event: any) {
    this.vinSn = event.target.value;

    this.decodedMake = await this.vinData.getMake(
      this.vinSn.slice(0, 3).toLocaleUpperCase()
    );
    console.log('decoded make', this.vinSn, this.decodedMake);
    if (this.decodedMake !== '') {
      this.addAttributesForm.get('attributes')['controls'][0].setValue({
        trait_type: 'Make',
        value: this.decodedMake,
      });
    }
  }

  async approveToken() {
    this.loading = true;
    try {
      const aprovAmount = this.math.toBlockchainValue(this.mintFee);
      await this.vinnft.approveToken(aprovAmount);
      setTimeout(() => {
        this.loadFeeTokenData();
      }, 2000);
      this.toastr.success('Approved!');
    } catch (error: any) {
      console.log('approve err', error);
      this.toastr.error(
        error?.message ?? 'Engine stalled. Something went wrong'
      );
    }
    this.loading = false;
  }

  async openMintModal() {
    if (!this.validateMint()) return;
    if (this._pricePlan == 'free' || this.mintFee == 0) {
      this.onSubmit();
    } else {
      this.openModal('select-mint-mode-modal');
    }
  }

  async mintPrimary() {
    if (!this.authService.isLogin) {
      this.toastr.error('Please login first to create ServiceRecords');
      this.modalService.open('login-modal');
      return;
    }

    if (!this.cardInfo) {
      this.toastr.error(
        'Cannot get stripe elements. Please refresh the page to retry.'
      );
      return;
    }
    const stripeInstance = await this.stripeService.setPublishableKey(
      environment.stripeInfo.PUBLISHABLE_KEY
    );
    this.closeModal('select-mint-mode-modal');
    this.stripe = stripeInstance;
    const elements = await stripeInstance.elements();
    this.card = await elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
    this.openModal('stripe-checkout-modal');
  }

  async mergeTwo() {
    const nftImage = new Image();
    nftImage.onload = () => {
      const badgeWidth = nftImage.width * 0.3;
      const badgeHeight = badgeWidth / 5;
      console.log('img sizes ', nftImage.width, badgeWidth, badgeHeight);

      mergeImages(
        [
          {
            src: this.nft_image_content,
            x: 0,
            y: 0,
          },
          {
            src: '../../../assets/images/vin-track-new.png',
            x: 50,
            y: 50,
            width: badgeWidth,
            height: badgeHeight,
          },
        ],
        {
          quality: 1,
          format:
            this.ipfsExtension.nft_image === 'png' ? 'image/png' : 'image/jpeg',
        }
      ).then((b64: any) => {
        this.nft_image_content = b64;
      });
    };

    nftImage.src = this.nft_image_content;
  }

  async uploadToIpfs(): Promise<boolean> {
    try {
      // Upload NFT Image to ipfs
      const nftBlob = await (await fetch(this.nft_image_content)).blob();
      const file = new File([nftBlob], 'File name', { type: 'image/png' });
      let filePath =
        'images/' + Math.random() * 10000000000000000 + '_' + this.name;
      let S3BucketImage = await this.offchain.uploadToS3Service(file, filePath);
      this.img_ipfs_uri = S3BucketImage.Location;

      // Upload Extra medias
      const extras = [];
      for (let idx = 0; idx < this.additionMediaFiles.length; idx++) {
        const _media = this.additionMedias[idx];
        let filePath1 = 'images/' + Math.random() * 10000000000000000;
        const extraS3BucketImage = await this.offchain.uploadToS3Service(
          this.additionMediaFiles[idx],
          filePath1 + `${this.name}_additional_${idx + 1}`
        );
        const extra_ipfs_uri = extraS3BucketImage.Location;
        extras.push({
          content: extra_ipfs_uri,
          isVideo: _media.isVideo,
        });
      }

      const tokenUri: VinNftType = {
        name: this.name,
        description: this.description,
        links: this.links,
        image: this.img_ipfs_uri,
        attributes: this.traitsArray,
        vinSn: this.vinSn,
        isVideo: this.isVideo,
        package: this._pricePlan,
        extras,
        miles: '',
        color: '',
        interiorColor: '',
        motor: '',
        transmission: '',
        vin: '',
        model: '',
        year: '',
        selectedOptions: '',
        otherDescription: '',
        acquisitionDescription: '',
        specialDescription: '',
        isForSale: false,
        price: '',
      };

      const tokenRecord: any = {
        ext_record: [],
        service_record: [],
        ownership: [],
      };

      // Check Record Documents
      if (this.uploadData.ext_record.length > 0) {
        // Save External Record to token uri
        for (let idx = 0; idx < this.uploadData.ext_record.length; idx++) {
          const extItem = this.uploadData.ext_record[idx];
          // const record_ipfs_url = await this.offchain.uploadToPinata(
          //   extItem.file,
          //   `${this.name}_externalRecord_${idx + 1}`
          // );
          let filePath1 = 'images/' + Math.random() * 10000000000000000;
          const recordS3BucketImage = await this.offchain.uploadToS3Service(
            extItem.file,
            filePath1 + `${this.name}_externalRecord_${idx + 1}`
          );
          const record_ipfs_url = recordS3BucketImage.Location;
          tokenRecord.ext_record.push({
            url: record_ipfs_url,
            type: extItem.type,
          });
        }
      }
      if (this.uploadData.service_record.length > 0) {
        // Save Service Record to token uri
        for (let idx = 0; idx < this.uploadData.service_record.length; idx++) {
          const serviceItem = this.uploadData.service_record[idx];
          // const record_ipfs_url = await this.offchain.uploadToPinata(
          //   serviceItem.file,
          //   `${this.name}_serviceRecord_${idx + 1}`
          // );
          let filePath1 = 'images/' + Math.random() * 10000000000000000;
          const recordS3BucketImage = await this.offchain.uploadToS3Service(
            serviceItem.file,
            filePath1 + `${this.name}_serviceRecord_${idx + 1}`
          );
          const record_ipfs_url = recordS3BucketImage.Location;
          tokenRecord.service_record.push({
            url: record_ipfs_url,
            type: serviceItem.type,
          });
        }
      }
      if (this.uploadData.ownership.length > 0) {
        // Save Service Record to token uri
        for (let idx = 0; idx < this.uploadData.ownership.length; idx++) {
          const ownershipItem = this.uploadData.ownership[idx];
          // const record_ipfs_url = await this.offchain.uploadToPinata(
          //   ownershipItem.file,
          //   `${this.name}_ownershipRecord_${idx + 1}`
          // );
          let filePath1 = 'images/' + Math.random() * 10000000000000000;
          const recordS3BucketImage = await this.offchain.uploadToS3Service(
            ownershipItem.file,
            filePath1 + `${this.name}_ownershipRecord_${idx + 1}`
          );
          const record_ipfs_url = recordS3BucketImage.Location;
          tokenRecord.ownership.push({
            url: record_ipfs_url,
            type: ownershipItem.type,
          });
        }
      }

      if (this.password && this.password.length > 0) {
        this.token_private_metadata = JSON.stringify(tokenRecord);
        this.token_private_ipfs_uri = await this.offchain.uploadJsonToHeroku(
          tokenRecord
        );
      } else {
        tokenUri.records = tokenRecord;
      }
      this.token_metadata = JSON.stringify(tokenUri);
      console.log(tokenUri, tokenRecord);
      this.token_ipfs_uri = await this.offchain.uploadJsonToHeroku(tokenUri);
      console.log(this.token_ipfs_uri, this.token_private_ipfs_uri);
      return true;
    } catch (error: any) {
      this.toastr.error(error?.message, 'Error');
      console.log('Upload To Ipfs error: ', error);
      return false;
    }
  }

  async mintVin(): Promise<void> {
    this.closeModal('select-mint-mode-modal');
    this.loading = true;

    await this.uploadToIpfs();
    try {
      await this.vinnft.mint(
        this._receiver,
        this.name,
        this.img_ipfs_uri,
        this.vinSn,
        this.token_ipfs_uri,
        this.token_private_ipfs_uri,
        this.refCode,
        this._pricePlan,
        this.token_metadata,
        this.token_private_metadata,
        this.authService.curUser?.email ?? '',
        this.password
      );
      this.toastr.success(
        'Minted ServiceRecords successfully. It will be searchable in our database shortly. '
      );
      this.router.navigate(['/']);
    } catch (error: any) {
      this.toastr.error(error?.message, 'Error');
      console.log('Mint ServiceRecords error: ', error);
    }
    this.loading = false;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  async onFileChanged(event: any, type = 'nft_image') {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }

    if (type === 'nft_image') {
      this.uploadData[type] = event.target.files[0];
      const fileType = this.checkIsVideo(this.uploadData[type].name, type);
      this.isVideo = fileType === 2;
      var reader = new FileReader();
      reader.readAsDataURL(this.uploadData[type]);

      reader.onload = (_event) => {
        this.nft_image_content = reader.result;
        if (!this.isVideo) this.mergeTwo();
      };
    } else {
      this.uploadType = type;
      this.tmpDataType = '';
      this.tmpUploadFile = event.target.files[0];

      this.openModal('upload-external-modal');
    }
  }

  getUploadTypeText() {
    if (this.uploadType === 'ext_record') return 'External Record';
    if (this.uploadType === 'service_record') return 'Service Record';
    if (this.uploadType === 'ownership') return 'Ownership Document';
    return '';
  }

  confirmUpload() {
    this.uploadData[this.uploadType].push({
      file: this.tmpUploadFile,
      type: this.tmpDataType,
    });
    this.closeModal('upload-external-modal');
  }

  addExtraMediaFile(extraFile: any) {
    const fileType = this.checkIsVideo(extraFile.name, '');

    var reader = new FileReader();
    reader.readAsDataURL(extraFile);
    reader.onload = (_event) => {
      this.additionMediaFiles[this.currentMediaCount] = extraFile;
      this.additionMedias[this.currentMediaCount++] = {
        content: reader.result,
        isVideo: fileType === 2,
      };
    };
  }

  async addExtraFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }

    if (event.target.files.length + this.additionMediaFiles.length > 10) {
      this.toastr.warning(
        'Upload limit is 10 per time, please update your ServiceRecords to add more.'
      );
      return;
    }
    const videoCnt = [];
    for (let idx = 0; idx < event.target.files.length; idx++) {
      const element = event.target.files[idx];
      const fileType = this.checkIsVideo(element.name, '');
      if (fileType == 2) videoCnt.push(fileType);
    }
    if (videoCnt.length > 1) {
      this.toastr.warning('You can upload 1 video per time.');
      return;
    }
    console.log(event.target.files.length, 'event.target.files');
    for (let idx = 0; idx < event.target.files.length; idx++) {
      const element = event.target.files[idx];
      await this.addExtraMediaFile(element);
    }
  }

  closeExtra(_index: number) {
    this.currentMediaCount--;
    this.additionMedias.splice(_index, 1);
    this.additionMediaFiles.splice(_index, 1);
  }

  closeRecord(_index: number, _type: string) {
    this.uploadData[_type].splice(_index, 1);
    console.log(this.uploadData, _index, _type);
  }

  checkIsVideo(fileName: string, type: string) {
    const allowedImageFiles = ['.png', '.jpg', '.jpeg', '.gif'];
    const allowedVideoFiles = ['.mp4', '.mov', '.wmv', '.avi'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName.toLowerCase());
    if (undefined !== extension && null !== extension) {
      this.ipfsExtension[type] = extension[0];
      for (const ext of allowedImageFiles) {
        if (ext === extension[0]) return 1; // Image
      }
      for (const ext of allowedVideoFiles) {
        if (ext === extension[0]) return 2; // Video
      }
    }
    return 0;
  }

  getFileType(fileName: string) {
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      return extension[0];
    }
    return 'unknown';
  }

  setTraits() {
    // GET TRAITS DATA AND SUBMIT HERE
    if (this.addAttributesForm.status == 'VALID') {
      this.traitsArray = this.addAttributesForm.value.attributes;
    }
  }

  get attributes(): FormArray {
    return <FormArray>this.addAttributesForm.get('attributes');
  }

  removeTrait(data: any) {
    if (this.attributes.length !== 1) this.attributes.removeAt(data);
  }
  addTrait() {
    // if (this.attributes.length !== 11)
    this.attributes.push(this.createTraitInput('', ''));
  }

  createTraitInput(type: string, value: string): FormGroup {
    return this.fb.group({
      trait_type: [type, Validators.required],
      value: [value, Validators.required],
    });
  }

  private get priceTraitGroup(): FormGroup | null {
    return (
      (this.traitsArray.controls as FormGroup[]).find(
        (group) => group.get('trait_type')?.value === 'Price'
      ) || null
    );
  }

  toggle_for_sale() {
    this.isForSale = !this.isForSale;
    if (!this.isForSale) {
      this.price = '';
    }
  }

  new_price() {
    const priceControl = this.priceTraitControl;
    if (priceControl) {
      // If the "Price" trait exists, just update its value
      priceControl.get('value')?.setValue(this.price);
    } else {
      // If the "Price" trait doesn't exist, create and add it
      const newPriceControl = this.createTraitInput('Price', this.price);
      this.attributes.push(newPriceControl);
    }
  }

  // set trait_type = "Price" to the value of the new price

  // ## GUI STUFF

  async toggleWeb3() {
    if (!this._minter) {
      // No wallet connected
      await this.wallet.connectWallet();
      this.setReceiver();
    }
    const network = await this.wallet.getNetwork();
    if (network === 'UNSUPPORTED') await this.wallet.switchToMatic();
    this.showWeb3Payment = !this.showWeb3Payment;
  }

  // UTIL

  generateGUID() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-4' +
      S4().substr(0, 3) +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }
}
