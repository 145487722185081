import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/components/_modal';
import { VinNFTService } from 'src/app/services/vinNFT.service';
import { WalletService } from 'src/app/services/wallet.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
} from '@angular/forms';
import { MathService } from 'src/app/services/math.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { VindataserviceService } from 'src/app/services/vindataservice.service';
import { AuthService } from 'src/app/services/auth.service';
import mergeImages from '../utils/myMergeImages';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { e } from 'mathjs';
import { env } from 'process';
import { CookieService } from 'ngx-cookie-service';
import { error, log } from 'console';
import { AiService } from '../services/ai.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-create-wizard-form',
  templateUrl: './create-wizard-form.component.html',
  styleUrls: ['./create-wizard-form.component.scss'],
})
export class CreateWizardFormComponent implements OnInit {
  private statusSubscription: Subscription;
  status_text: string =
    'Initiating vehicle description generation. Please hold on.';
  suffix_paragraph: string =
    'This vehicle now has its own ServiceRecords to document and retain its history and provenance.';

  max_ai_gen = 5;
  ai_gen_count = 0;

  private statusMessages: string[] = [
    'Initiating vehicle description generation. Please hold on.',
    'AI is Preparing description...Please be patient.',
    'Currently analyzing vehicle data. Processing in progress.',
    'Working on vehicle description. This may take a few moments.',
    'Continuing to generate vehicle details. Thank you for your patience.',
    "Vehicle analysis ongoing. We're committed to accuracy.",
    'Progressing with the vehicle description. Your request is important to us.',
    'Still at it! Compiling comprehensive vehicle information.',
    'Vehicle description is being fine-tuned. Almost there.',
    'AI is experiencing heavy traffic. Please be patient.',
    'In the final stages of generating your vehicle description.',
    'Completing vehicle description generation. Ready shortly. AI is experiencing heavy traffic. Please be patient.',
  ];

  private currentIndex: number = 0;
  private message_refresh_interval = 10000; // 10 seconds

  currentStep: number = 1;
  lastStep: number = 5;
  revising: boolean = false;
  carInfo: string = '';
  miles: string = '';
  year: string = '';
  model: string = '';
  trim: string = '';
  color: string = '';
  interiorColor: string = '';
  motor: string = '';
  transmission: string = '';
  vin: string = '';
  options: string[] = [];
  acquisitionDescription: string = '';
  specialDescription: string = '';
  new_instruction: string = '';

  price: string = '';
  selectedPackage: string = '';
  vaultPassword: string = '';
  externalLinks: string = '';
  nft_image_content: any;
  stepValidate: boolean = false;
  isForSale: any = 'No';
  isOwner: boolean = true;
  owner: string = 'Owner';
  locationDescription: string = '';
  content_description: string = '';
  contentOption: string = ''; // Service Provider, Professional Photographer, Spotted / Fan
  selectedServices: string[] = [];
  provider_description: string = '';
  is_dealer = false;
  sticker: string = '';
  agreed_to_terms: boolean = false;

  descriptionLoad: boolean = false;
  uploadData: any = {
    nft_image: null,
    ext_record: [],
    service_record: [],
    ownership: [],
  };
  ipfsExtension: any = {
    nft_image: '',
    ext_record: [],
    service_record: [],
    ownership: [],
  };
  loading: boolean = false;
  showWeb3Payment: boolean = false;

  name = '';
  description = '';
  links: string = '';
  isVideo: boolean = false;
  traitsArray: any;
  addAttributesForm: any;
  vinSn: string = '';
  password: string = '';
  mintFee: number = 0;
  allowedAmount: number = 0;
  isAdmin: boolean = false;
  _minter: string | null = null;
  _receiver: string = '';

  //  ################## PRICING PLANS
  _pricePlan: string = 'enthusiast';
  priceEnthusiast = environment.mintFee.enthusiast;
  priceCollector = environment.mintFee.collector;
  priceProfessional = environment.mintFee.professional;
  priceFree = environment.mintFee.free;

  planList: any[];

  // ###### DEFAULTS
  refCode: string = '';
  refCodeAccepted = false;
  additionalMediaCount: number = 25;
  currentMediaCount: number = 0;
  additionMedias: any[] = [];
  additionMediaFiles: any[] = [];
  mintWay: string = '';
  img_ipfs_uri: string;
  token_ipfs_uri: string;
  token_metadata: string;
  token_private_ipfs_uri: string = '';
  token_private_metadata: string = '';

  wyre_orderId: string;
  wyre_transferId: string;
  decodedMake: string;

  uploadType: string = 'ext_record';
  tmpUploadFile: File;
  tmpDataType: string;
  vehicleOptions = [
    // Vehicle Condition
    'Original',
    'Restored',
    'Project',
    'Modified',
    'Unmodified',
    'Like new',

    // Ownership and History
    'Long term ownership',
    'Original owner',
    'Service history available',
    'Antique',

    // Documentation and Authenticity
    'New',
    'Matching numbers',
    'Original miles',
    'Documented',
    'Rare',

    // Physical Appearance
    'Barn Find',
    'Original color',
    'Original paint',

    // Operational Status
    'Runs',
    'Runs Great',
    'Does not run',

    // Title and Legal Status
    'Clear Title',
    'Lien',
    'Salvage',
    'Manual',

    // General Knowledge
    'I do not know much about this vehicle',
  ];

  selectedOptions = {};

  giftOptions: string[] = [
    'Digital Provenance',
    'Secure History',
    'Ownership Story',
    'Car Life Journey',
    'Service Track Record',
    'Authenticity Verified',
    'Collector’s Detail',
    'Transfer Ease',
    'Investment Protection',
  ];

  // Object to hold the selected gift options
  selectedGiftOptions: { [key: string]: boolean } = {};

  otherDescription: string = '';
  docType: any = {
    ext_record: [
      {
        name: 'Build sheet',
      },
      {
        name: 'Warranty card',
      },
      {
        name: 'CARFAX report',
      },
      {
        name: 'Sales brochure',
      },
    ],
    service_record: [
      {
        name: 'Service record',
      },
      {
        name: 'Parts receipt',
      },
      {
        name: 'Mileage log',
      },
    ],
    ownership: [
      {
        name: 'Title',
      },
      {
        name: 'Registration',
      },
      {
        name: 'Bill of Sale',
      },
    ],
  };
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  stripeError: any;
  stripe: any;
  card: any;
  cardHandler = this.onStripeChange.bind(this);
  stripeProcessing: boolean = false;

  productID: string;
  clientID: string;
  inputText: any;
  result: string;
  errorMessage: string;
  get priceTraitControl(): FormGroup | null {
    const index = this.attributes.controls.findIndex(
      (control) => control.get('trait_type')?.value === 'Price'
    );
    if (index !== -1) {
      return this.attributes.at(index) as FormGroup;
    }
    return null;
  }
  constructor(
    private modalService: ModalService,
    private fb: FormBuilder,
    private wallet: WalletService,
    private vinnft: VinNFTService,
    private readonly math: MathService,
    private readonly toastr: ToastrService,
    private readonly route: ActivatedRoute,
    private readonly offchain: OffchainService,
    private readonly vinData: VindataserviceService,
    public readonly authService: AuthService,
    private stripeService: AngularStripeService,
    private router: Router,
    private cookieService: CookieService,
    private readonly aiService: AiService
  ) {}
  async ngOnInit() {
    if (!this.authService.isLogin) {
      this.toastr.error('Please login first to create ServiceRecords');
      this.modalService.open('login-modal');
      return;
    }
    this.route.params.subscribe((queryParams) => {
      this.loadPlans();
      console.log(queryParams, 'queryParams');
      this._pricePlan = queryParams['plan'];
      this.changedPricePlan();

      this.refCode = this.cookieService.get('referrer_discount_code');
      console.log('RefCode =', this.refCode);

      // if (queryParams['refCode']) {
      //   this.refCode = queryParams['refCode'];
      //   this.changedRefCode();
      // }
    });
    this.addAttributesForm = this.fb.group({
      // attributes: this.fb.array([this.initialValue], Validators.required),
      attributes: this.fb.array(
        [
          this.createTraitInput('Make', ''),
          this.createTraitInput('Model', ''),
          this.createTraitInput('Year', ''),
          this.createTraitInput('Motor Transmission', ''),
          this.createTraitInput('Color Exterior', ''),
          this.createTraitInput('Price', ''),
        ],
        Validators.required
      ),
    });

    this.setReceiver();
    this.initUpdateStatus();
  }

  ngOnDestroy() {
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

  private initUpdateStatus() {
    const source = interval(this.message_refresh_interval);
    this.statusSubscription = source.subscribe(() => {
      this.updateStatus();
    });
  }

  private updateStatus() {
    this.status_text = this.statusMessages[this.currentIndex];
    this.currentIndex = (this.currentIndex + 1) % this.statusMessages.length;
  }

  ngAfterViewInit(): void {
    // const container = document.getElementById("scroll-container");
    // if(!container) {
    //   console.log("Error loading component ng182");
    //   return;}
    // const windowHeight = window.innerHeight;
    // const containerHeight = windowHeight - (windowHeight * 0.1); // subtract 10%
    // container.style.height = containerHeight + "px";
  }

  onStripeChange(error: any) {
    console.log(error, this, 'stripe change erorr');
    if (error.error) {
      this.stripeError = error.error.message;
    } else {
      this.stripeError = null;
    }
  }

  async openAttributeForm() {
    this.openModal('vehicle-detail-modal');
  }

  async processStripePayment(): Promise<any> {
    this.stripeProcessing = true;
    this.stripeError = null;
    try {
      const intent = await this.offchain.stripeCreateIntent(this.mintFee);
      const result = await this.stripe.confirmCardPayment(
        intent.client_secret,
        {
          payment_method: {
            card: this.card,
          },
        }
      );
      console.log(result, 'stripe confirm');
      if (result.error) {
        this.stripeError = result.error.message;
        throw Error;
      }
      return result;
    } catch (error) {
      console.log(error, 'stripe confirm error');
      this.toastr.error('Stripe Error');
      throw error;
    } finally {
      this.stripeProcessing = false;
    }
  }

  async onSubmit() {
    document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start', // Align the top of the element to the top of the viewport
      inline: 'nearest', // Let the browser choose the nearest horizontal alignment
    });
    try {
      this.setWizardTraits();
      console.log(this._pricePlan, this.mintFee);
      var pricePlan = this._pricePlan;
      var id = this.generateGUID();
      if (pricePlan == 'free' || this.mintFee == 0) {
        pricePlan = 'enthusiast';
      } else {
        const result = await this.processStripePayment();

        if (result.paymentIntent?.status === 'succeeded') {
          this.toastr.success(
            'Success! Your payment have been processed successfully! Please wait while ServiceRecords information is synchronised.'
          );
          this.closeModal('stripe-checkout-modal');
          id = result.paymentIntent.id;
        }
      }
      this.loading = true;
      console.log('Uploading to Ipfs...');
      await this.uploadToIpfs();
      console.log('Uploading to Ipfs...Done');
      const createdNft = await this.offchain.primaryMint({
        name: this.name,
        description: this.description,
        miles: this.miles,
        color: this.color,
        year: this.year,
        model: this.model,
        interiorColor: this.interiorColor,
        motor: this.motor,
        transmission: this.transmission,
        vin: this.vin,
        options: this.selectedOptions,
        otherDescription: this.otherDescription,
        acquisitionDescription: this.acquisitionDescription,
        specialDescription: this.specialDescription,
        image: this.img_ipfs_uri,
        isForSale: this.isForSale,
        price: this.price,
        //vin: this.vinSn,
        tokenUri: this.token_ipfs_uri,
        tokenPrivateUri: this.token_private_ipfs_uri,
        pkg: pricePlan,
        promoCode: this.refCode,
        id: id,
      });
      await this.offchain.createNft(
        createdNft.tokenId,
        // this.authService.curUser.email,
        this.authService.curUser._id,
        this.token_ipfs_uri,
        this.token_metadata,
        this.token_private_metadata,
        this.refCodeAccepted ? this.refCode : '',
        this.authService.curUser.email,
        this.password
      );
      this.toastr.success('Successfully minted');
      this.loading = false;
      this.router.navigate(['/item/' + createdNft.tokenId]);
    } catch (error) {
      console.log('Error was happened -->', error);
      this.loading = false;
      this.toastr.error(
        'The error was happened while uploading media files.',
        'Error'
      );
      // Handle any other errors here
    }
  }
  async checkStepValidate() {
    if (!this.authService.isLogin) {
      this.toastr.error('Please login first to create ServiceRecords');
      this.modalService.open('login-modal');
      return;
    } else {
      if (this.currentStep == 1) {
        if (this.name == '') {
          this.toastr.error(
            'Please enter at least vehicle make & model',
            'Error'
          );
          this.stepValidate = false;
        } else {
          this.stepValidate = true;
        }
      }

      if (this.currentStep == 2) {
        //this.miles == '' || this.color == '' || this.interiorColor == '' || this.motor == '' || this.transmission == '' ||
        // if(this.vin == ''){
        //   this.toastr.error('Please enter VIN', 'Error');
        //   this.stepValidate = false;
        // }else{
        //   this.stepValidate = true;
        // }
      }

      // if(this.currentStep == 6){
      //   var pricePlan = this._pricePlan;
      //   await this.callApi();
      // }
    }

    // if(this.currentStep == 3){
    //   if(this.otherDescription == ''){
    //     this.toastr.error('Please enter description', 'Error');
    //     this.stepValidate = false;
    //   }else{
    //     this.stepValidate = true;
    //   }
    // }
  }
  async setReceiver() {
    try {
      this._minter = await this.wallet.getAccount();
      console.log(this._minter, 'minter wallet');
      if (this._minter) {
        this._receiver = this._minter;
        this.loadFeeTokenData();
        const feeWallet = await this.vinnft.getFeeWallet();
        console.log(feeWallet, 'feeWallet');
        if (feeWallet.toLowerCase() === this._minter.toLowerCase())
          this.isAdmin = true;
      }
    } catch (error) {
      console.log(error, 'setReceiver');
    }
  }

  async loadFeeTokenData() {
    if (this.mintFee === 0 && this._pricePlan != 'free')
      await this.getMintFeeByPlan();
    this.allowedAmount = this.math.toHumanValue(
      await this.vinnft.allowedToken()
    );
  }

  loadDefaultPrices() {
    this.priceEnthusiast = environment.mintFee.enthusiast;
    this.priceCollector = environment.mintFee.collector;
    this.priceProfessional = environment.mintFee.professional;
    this.priceFree = environment.mintFee.free;
  }

  loadPlans() {
    this.planList = [
      {
        name: 'ServiceRecords $' + this.priceEnthusiast.toString(),
        id: 'enthusiast',
        addtionalCnt: 25,
      },
      {
        name: 'ServiceRecords Pro $' + this.priceProfessional.toString(),
        id: 'professional',
        addtionalCnt: 250,
      },
    ];

    console.log('Plan List');
    console.log(this.planList);
  }

  changedPricePlan() {
    this.loadDefaultPrices();
    this.planList.map((item) => {
      if (item.id === this._pricePlan)
        this.additionalMediaCount = item.addtionalCnt;
    });
    this.additionMedias.splice(this.additionalMediaCount, 100);
    this.additionMediaFiles.splice(this.additionalMediaCount, 100);
    if (this.currentMediaCount > this.additionalMediaCount)
      this.currentMediaCount = this.additionalMediaCount;
    this.password = '';
    this.getMintFeeByPlan();
    if (this.refCode != '') {
      this.changedRefCode();
    }
  }

  changedRefCode() {
    this.loadDefaultPrices();
    let i = environment.refCodes.indexOf(this.refCode);
    if (i >= 0) {
      this.priceEnthusiast =
        Math.round(
          Math.max(0, this.priceEnthusiast - environment.refCodeValues[i]) * 100
        ) / 100;
      this.priceCollector =
        Math.round(
          Math.max(0, this.priceCollector - environment.refCodeValues[i] * 2) *
            100
        ) / 100;
      this.priceProfessional =
        Math.round(
          Math.max(
            0,
            this.priceProfessional - environment.refCodeValues[i] * 3
          ) * 100
        ) / 100;
      console.log('RefCode Accepted');
      this.refCodeAccepted = true;
      this.getMintFeeByPlan();
      this.loadPlans();
    } else {
      console.log('RefCode Invalid');
      this.refCodeAccepted = false;
      this.getMintFeeByPlan();
      this.loadPlans();
    }
  }

  async getMintFeeByPlan() {
    // this.mintFee = this.vinnft.getMintFee(this._pricePlan);

    if (this._pricePlan == 'enthusiast') {
      this.mintFee = this.priceEnthusiast;
    } else if (this._pricePlan == 'collector') {
      this.mintFee = this.priceCollector;
    } else if (this._pricePlan == 'professional') {
      this.mintFee = this.priceProfessional;
    } else if (this._pricePlan == 'free') {
      this.mintFee = 0;
    }

    console.log(this._pricePlan, this.mintFee);
  }

  validateMint(): boolean {
    if (!this.uploadData.nft_image) {
      this.toastr.error('Please upload main image/video.', 'Error');
      return false;
    }
    if (!this.name || this.name === '') {
      this.toastr.error('Please enter the name for the vehicle', 'Error');
      return false;
    }

    if (!this.description || this.description === '') {
      this.toastr.error('Please enter the description of the vehicle', 'Error');
      return false;
    }
    // if (!this.vinSn || this.vinSn === '' || this.vinSn.length != 17) {
    //   this.toastr.error('Please enter the 17-character VIN', 'Error');
    //   return false;
    // }
    // #### NO MORE REQUIREMENT FOR HAVING AT LEAST ONE ATTRIBUTE
    // if (this.addAttributesForm.status !== 'VALID') {
    //   this.toastr.error('Please fill-in the vehicle details.', 'Error');
    //   return false;
    // }
    return true;
  }

  async decodeMake(event: any) {
    this.vinSn = event.target.value;

    this.decodedMake = await this.vinData.getMake(
      this.vinSn.slice(0, 3).toLocaleUpperCase()
    );
    console.log('decoded make', this.vinSn, this.decodedMake);
    if (this.decodedMake !== '') {
      this.addAttributesForm.get('attributes')['controls'][0].setValue({
        trait_type: 'Make',
        value: this.decodedMake,
      });
    }
  }

  async approveToken() {
    this.loading = true;
    try {
      const aprovAmount = this.math.toBlockchainValue(this.mintFee);
      await this.vinnft.approveToken(aprovAmount);
      setTimeout(() => {
        this.loadFeeTokenData();
      }, 2000);
      this.toastr.success('Approved!');
    } catch (error: any) {
      console.log('approve err', error);
      this.toastr.error(
        error?.message ?? 'Engine stalled. Something went wrong'
      );
    }
    this.loading = false;
  }

  async openMintModal() {
    if (!this.validateMint()) return;
    if (this._pricePlan == 'free' || this.mintFee == 0) {
      this.onSubmit();
    } else {
      this.openModal('select-mint-mode-modal');
    }
  }

  async mintPrimary() {
    if (!this.authService.isLogin) {
      this.toastr.error('Please login first to create ServiceRecords');
      this.modalService.open('login-modal');
      return;
    }

    if (!this.cardInfo) {
      this.toastr.error(
        'Cannot get stripe elements. Please refresh the page to retry.'
      );
      return;
    }
    const stripeInstance = await this.stripeService.setPublishableKey(
      environment.stripeInfo.PUBLISHABLE_KEY
    );
    this.closeModal('select-mint-mode-modal');
    this.stripe = stripeInstance;
    const elements = await stripeInstance.elements();
    this.card = await elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
    this.openModal('stripe-checkout-modal');
  }

  async mergeTwo() {
    const nftImage = new Image();
    nftImage.onload = () => {
      const badgeWidth = nftImage.width * 0.3;
      const badgeHeight = badgeWidth / 5;
      console.log('img sizes ', nftImage.width, badgeWidth, badgeHeight);

      mergeImages(
        [
          {
            src: this.nft_image_content,
            x: 0,
            y: 0,
          },
          {
            src: '../../../assets/images/vin-track-new.png',
            x: 50,
            y: 50,
            width: badgeWidth,
            height: badgeHeight,
          },
        ],
        {
          quality: 1,
          format:
            this.ipfsExtension.nft_image === 'png' ? 'image/png' : 'image/jpeg',
        }
      ).then((b64: any) => {
        this.nft_image_content = b64;
      });
    };

    nftImage.src = this.nft_image_content;
  }

  async uploadToIpfs(): Promise<boolean> {
    try {
      // Upload NFT Image to ipfs
      const nftBlob = await (await fetch(this.nft_image_content)).blob();
      const file = new File([nftBlob], 'File name', { type: 'image/png' });
      let filePath =
        'images/' + Math.random() * 10000000000000000 + '_' + this.name;
      let S3BucketImage = await this.offchain.uploadToS3Service(file, filePath);
      this.img_ipfs_uri = S3BucketImage.Location;

      // Upload Extra medias
      const extras = [];
      for (let idx = 0; idx < this.additionMediaFiles.length; idx++) {
        const _media = this.additionMedias[idx];
        let filePath1 = 'images/' + Math.random() * 10000000000000000;
        const extraS3BucketImage = await this.offchain.uploadToS3Service(
          this.additionMediaFiles[idx],
          filePath1 + `${this.name}_additional_${idx + 1}`
        );
        const extra_ipfs_uri = extraS3BucketImage.Location;
        extras.push({
          content: extra_ipfs_uri,
          isVideo: _media.isVideo,
        });
      }

      const tokenUri: VinNftType = {
        name: this.name,
        links: this.links,
        image: this.img_ipfs_uri,
        attributes: this.traitsArray,
        vinSn: this.vinSn,
        isVideo: this.isVideo,
        package: this._pricePlan,
        motor: this.motor,
        transmission: this.transmission,
        vin: this.vin,
        selectedOptions: this.selectedOptions,
        otherDescription: this.otherDescription,
        acquisitionDescription: this.acquisitionDescription,
        specialDescription: this.specialDescription,
        isForSale: this.isForSale,
        price: this.price,
        year: this.year,
        model: this.model,
        extras,
        miles: this.miles,
        color: this.color,
        interiorColor: this.interiorColor,
        description: this.description,
      };

      const tokenRecord: any = {
        ext_record: [],
        service_record: [],
        ownership: [],
      };

      // Check Record Documents
      if (this.uploadData.ext_record.length > 0) {
        // Save External Record to token uri
        for (let idx = 0; idx < this.uploadData.ext_record.length; idx++) {
          const extItem = this.uploadData.ext_record[idx];
          // const record_ipfs_url = await this.offchain.uploadToPinata(
          //   extItem.file,
          //   `${this.name}_externalRecord_${idx + 1}`
          // );
          let filePath1 = 'images/' + Math.random() * 10000000000000000;
          const recordS3BucketImage = await this.offchain.uploadToS3Service(
            extItem.file,
            filePath1 + `${this.name}_externalRecord_${idx + 1}`
          );
          const record_ipfs_url = recordS3BucketImage.Location;
          tokenRecord.ext_record.push({
            url: record_ipfs_url,
            type: extItem.type,
          });
        }
      }
      if (this.uploadData.service_record.length > 0) {
        // Save Service Record to token uri
        for (let idx = 0; idx < this.uploadData.service_record.length; idx++) {
          const serviceItem = this.uploadData.service_record[idx];
          // const record_ipfs_url = await this.offchain.uploadToPinata(
          //   serviceItem.file,
          //   `${this.name}_serviceRecord_${idx + 1}`
          // );
          let filePath1 = 'images/' + Math.random() * 10000000000000000;
          const recordS3BucketImage = await this.offchain.uploadToS3Service(
            serviceItem.file,
            filePath1 + `${this.name}_serviceRecord_${idx + 1}`
          );
          const record_ipfs_url = recordS3BucketImage.Location;
          tokenRecord.service_record.push({
            url: record_ipfs_url,
            type: serviceItem.type,
          });
        }
      }
      if (this.uploadData.ownership.length > 0) {
        // Save Service Record to token uri
        for (let idx = 0; idx < this.uploadData.ownership.length; idx++) {
          const ownershipItem = this.uploadData.ownership[idx];
          // const record_ipfs_url = await this.offchain.uploadToPinata(
          //   ownershipItem.file,
          //   `${this.name}_ownershipRecord_${idx + 1}`
          // );
          let filePath1 = 'images/' + Math.random() * 10000000000000000;
          const recordS3BucketImage = await this.offchain.uploadToS3Service(
            ownershipItem.file,
            filePath1 + `${this.name}_ownershipRecord_${idx + 1}`
          );
          const record_ipfs_url = recordS3BucketImage.Location;
          tokenRecord.ownership.push({
            url: record_ipfs_url,
            type: ownershipItem.type,
          });
        }
      }

      if (this.password && this.password.length > 0) {
        this.token_private_metadata = JSON.stringify(tokenRecord);
        this.token_private_ipfs_uri = await this.offchain.uploadJsonToHeroku(
          tokenRecord
        );
      } else {
        tokenUri.records = tokenRecord;
      }
      this.token_metadata = JSON.stringify(tokenUri);
      console.log(tokenUri, tokenRecord);
      this.token_ipfs_uri = await this.offchain.uploadJsonToHeroku(tokenUri);
      console.log(this.token_ipfs_uri, this.token_private_ipfs_uri);
      return true;
    } catch (error: any) {
      this.toastr.error(error?.message, 'Error');
      console.log('Upload To Ipfs error: ', error);
      return false;
    }
  }

  async mintVin(): Promise<void> {
    this.closeModal('select-mint-mode-modal');
    this.loading = true;
    await this.uploadToIpfs();
    try {
      await this.vinnft.mint(
        this._receiver,
        this.name,
        this.img_ipfs_uri,
        this.vinSn,
        this.token_ipfs_uri,
        this.token_private_ipfs_uri,
        this.refCode,
        this._pricePlan,
        this.token_metadata,
        this.token_private_metadata,
        this.authService.curUser?.email ?? '',
        this.password
      );
      this.toastr.success(
        'Minted ServiceRecords successfully. It will be searchable in our database shortly. '
      );
      this.router.navigate(['/']);
    } catch (error: any) {
      this.toastr.error(error?.message, 'Error');
      console.log('Mint ServiceRecords error: ', error);
    }
    this.loading = false;
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  async onFileChanged(event: any, type = 'nft_image') {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }

    if (type === 'nft_image') {
      this.uploadData[type] = event.target.files[0];
      const fileType = this.checkIsVideo(this.uploadData[type].name, type);
      this.isVideo = fileType === 2;
      var reader = new FileReader();
      reader.readAsDataURL(this.uploadData[type]);

      reader.onload = (_event) => {
        this.nft_image_content = reader.result;
        if (!this.isVideo) this.mergeTwo();
      };
    } else {
      this.uploadType = type;
      this.tmpDataType = '';
      this.tmpUploadFile = event.target.files[0];

      this.openModal('upload-external-modal');
    }
  }

  getUploadTypeText() {
    if (this.uploadType === 'ext_record') return 'External Record';
    if (this.uploadType === 'service_record') return 'Service Record';
    if (this.uploadType === 'ownership') return 'Ownership Document';
    return '';
  }

  confirmUpload() {
    this.uploadData[this.uploadType].push({
      file: this.tmpUploadFile,
      type: this.tmpDataType,
    });
    this.closeModal('upload-external-modal');
  }

  addExtraMediaFile(extraFile: any) {
    const fileType = this.checkIsVideo(extraFile.name, '');

    var reader = new FileReader();
    reader.readAsDataURL(extraFile);
    reader.onload = (_event) => {
      this.additionMediaFiles[this.currentMediaCount] = extraFile;
      this.additionMedias[this.currentMediaCount++] = {
        content: reader.result,
        isVideo: fileType === 2,
      };
    };
  }

  async addExtraFile(event: any) {
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      return;
    }

    if (event.target.files.length + this.additionMediaFiles.length > 10) {
      this.toastr.warning(
        'Upload limit is 10 per time, please update your ServiceRecords to add more.'
      );
      return;
    }
    const videoCnt = [];
    for (let idx = 0; idx < event.target.files.length; idx++) {
      const element = event.target.files[idx];
      const fileType = this.checkIsVideo(element.name, '');
      if (fileType == 2) videoCnt.push(fileType);
    }
    if (videoCnt.length > 1) {
      this.toastr.warning('You can upload 1 video per time.');
      return;
    }
    console.log(event.target.files.length, 'event.target.files');
    for (let idx = 0; idx < event.target.files.length; idx++) {
      const element = event.target.files[idx];
      await this.addExtraMediaFile(element);
    }
  }

  closeExtra(_index: number) {
    this.currentMediaCount--;
    this.additionMedias.splice(_index, 1);
    this.additionMediaFiles.splice(_index, 1);
  }

  closeRecord(_index: number, _type: string) {
    this.uploadData[_type].splice(_index, 1);
    console.log(this.uploadData, _index, _type);
  }

  checkIsVideo(fileName: string, type: string) {
    const allowedImageFiles = ['.png', '.jpg', '.jpeg', '.gif'];
    const allowedVideoFiles = ['.mp4', '.mov', '.wmv', '.avi'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName.toLowerCase());
    if (undefined !== extension && null !== extension) {
      this.ipfsExtension[type] = extension[0];
      for (const ext of allowedImageFiles) {
        if (ext === extension[0]) return 1; // Image
      }
      for (const ext of allowedVideoFiles) {
        if (ext === extension[0]) return 2; // Video
      }
    }
    return 0;
  }

  getFileType(fileName: string) {
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      return extension[0];
    }
    return 'unknown';
  }

  setTraits() {
    // GET TRAITS DATA AND SUBMIT HERE
    if (this.addAttributesForm.status == 'VALID') {
      this.traitsArray = this.addAttributesForm.value.attributes;
    }
  }

  get attributes(): FormArray {
    return <FormArray>this.addAttributesForm.get('attributes');
  }

  removeTrait(data: any) {
    if (this.attributes.length !== 1) this.attributes.removeAt(data);
  }
  addTrait() {
    // if (this.attributes.length !== 11)
    this.attributes.push(this.createTraitInput('', ''));
  }

  createTraitInput(type: string, value: string): FormGroup {
    return this.fb.group({
      trait_type: [type, Validators.required],
      value: [value, Validators.required],
    });
  }

  private get priceTraitGroup(): FormGroup | null {
    return (
      (this.traitsArray.controls as FormGroup[]).find(
        (group) => group.get('trait_type')?.value === 'Price'
      ) || null
    );
  }

  toggle_for_sale() {
    this.isForSale = !this.isForSale;
    if (!this.isForSale) {
      this.price = '';
    }
  }

  // toggleOwnerQuestion() {
  // this.isOwner = !this.isOwner;
  // }

  toggleService(service: string) {
    const index = this.selectedServices.indexOf(service);
    if (index > -1) {
      this.selectedServices.splice(index, 1);
    } else {
      this.selectedServices.push(service);
    }
  }

  new_price() {
    const priceControl = this.priceTraitControl;
    if (priceControl) {
      // If the "Price" trait exists, just update its value
      priceControl.get('value')?.setValue(this.price);
    } else {
      // If the "Price" trait doesn't exist, create and add it
      const newPriceControl = this.createTraitInput('Price', this.price);
      this.attributes.push(newPriceControl);
    }
  }

  // set trait_type = "Price" to the value of the new price

  // ## GUI STUFF

  async toggleWeb3() {
    if (!this._minter) {
      // No wallet connected
      await this.wallet.connectWallet();
      this.setReceiver();
    }
    const network = await this.wallet.getNetwork();
    if (network === 'UNSUPPORTED') await this.wallet.switchToMatic();
    this.showWeb3Payment = !this.showWeb3Payment;
  }

  // UTIL

  generateGUID() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-4' +
      S4().substr(0, 3) +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }

  generated_name = false;

  generate_name(make_model: string) {
    if (this.generated_name) return this.name;

    var new_name = '';
    if (this.year != '' && !make_model.includes(this.year)) {
      new_name += this.year + ' ';
    }
    if (this.color != '' && !make_model.includes(this.color)) {
      new_name += this.color + ' ';
    }
    new_name += make_model + ' ';
    if (this.trim != '' && !make_model.includes(this.trim)) {
      new_name += this.trim + ' ';
    }
    if (this.interiorColor != '' && !make_model.includes(this.interiorColor)) {
      new_name += 'with ' + this.interiorColor + ' interior';
    }
    this.generated_name = true;
    return new_name;
  }

  skip_to_done() {
    if (this.currentStep < this.lastStep) {
      this.checkStepValidate();
      if (this.stepValidate) {
        this.revising = false;
        this.currentStep = this.lastStep;
      }
    }
  }

  nextStep() {
    if (this.currentStep < this.lastStep) {
      this.checkStepValidate();
    }
    // STEP LOGIC
    if (this.currentStep == 1 && !this.revising) {
      this.callApi();
      this.name = this.generate_name(this.name);
    } else if (this.currentStep == 4) {
      if (!this.agreed_to_terms) {
        this.toastr.error('Please agree to terms and conditions', 'Important');
        this.stepValidate = false;
      }
      if (this.nft_image_content == null) {
        this.toastr.error('Please upload main image/video', 'Error');
        this.stepValidate = false;
      } else {
        this.stepValidate = true;
      }
    }

    if (this.stepValidate) {
      this.currentStep++;
    }
  }

  prevStep() {
    if (this.currentStep > 1) {
      this.currentStep--;
      this.stepValidate = true;
    }
  }

  editStep(step: number) {
    this.revising = true;
    this.currentStep = step;
  }

  handleNumericInput(event: KeyboardEvent) {
    const input = event.key;

    const numericPattern = /^[0-9.]$/;
    if (
      !numericPattern.test(input) &&
      input !== 'Backspace' &&
      input !== 'Delete'
    ) {
      event.preventDefault();
    }
  }
  handleAlphanumericInput(event: KeyboardEvent) {
    //this.vin = this.vin.toUpperCase();

    const input = event.key;

    const alphanumericPattern = /^[a-zA-Z0-9]+$/;
    if (
      !alphanumericPattern.test(input) &&
      input !== 'Backspace' &&
      input !== 'Delete'
    ) {
      event.preventDefault();
    }

    if (this.vin.length >= 17) {
      this.callVinData();
    }
  }

  async callVinData() {
    var vin_data = await this.vinData.getVinDetailsfromNHTSA(
      this.vin,
      this.year
    );
    this.motor = vin_data.EngineInfo || this.motor;
    this.transmission = vin_data.TransmissionInfo || this.transmission;
    console.log('VIN DATA');
    console.log(vin_data);
  }
  async callApi() {
    if (this.ai_gen_count >= this.max_ai_gen) {
      console.log('Maximum AI Gen Reached!');
      return;
    }
    if (this.new_instruction != '') {
      this.specialDescription += ' ' + this.new_instruction;
    }
    var instructions = '';
    if (this.is_dealer) {
      instructions = environment.instructions.is_dealer;
    } else if (this.isOwner && this.isForSale) {
      instructions = environment.instructions.is_owner_is_for_sale;
    } else if (this.isOwner && !this.isForSale) {
      instructions = environment.instructions.is_owner_not_for_sale;
    } else if (this.contentOption == 'Service Provider') {
      instructions = environment.instructions.service_provider;
    } else if (this.contentOption == 'Professional Photographer') {
      instructions = environment.instructions.professional_photographer;
    } else if (this.contentOption == 'Spotted / Fan') {
      instructions = environment.instructions.spotted_fan;
    } else if (this.contentOption == 'Gift') {
      instructions = environment.instructions.gift;
    }

    if (!this.isOwner) {
      this.owner = 'Not Owner';
    } else {
      this.owner = 'Owner';
    }
    if (this.is_dealer) {
      this.owner += ' Dealer';
    }

    if (this.sticker != '') {
      this.otherDescription +=
        '\nSTICKER INFORMATION FROM DEALER:\n' + this.sticker;
    }

    this.inputText = this.buildInputText(instructions);

    this.descriptionLoad = false;

    this.ai_gen_count += 1;
    var ai_description = await this.aiService.callApi_GetDescription(
      this.inputText
    );
    if (ai_description != 'FAIL') {
      //this.result = result.result;
      this.description = ai_description + '\n\n' + this.suffix_paragraph;
      if (this.is_dealer && this.sticker != '') {
        this.description =
          this.description +
          '\n\nSTICKER INFORMATION FROM DEALER:\n' +
          this.sticker;
      }
      this.descriptionLoad = true;

      //console.log('DESCRIPTION: ' + this.description);
      // console.log('Description --------> ' + this.description);
      // console.log('Image --------> ' + this.nft_image_content);
      // console.log('Vin --------> ' + this.vin);
      // console.log('Price --------> ' + this.price);
    } else {
      this.description = 'The pictures speak for themselves.';
      this.descriptionLoad = true;
      this.ai_gen_count -= 1;
    }
  }

  private setWizardTraits() {
    var properties = this.buildInputText();
    for (const key in properties) {
      if (
        properties.hasOwnProperty(key) &&
        properties[key] !== null &&
        properties[key] !== ''
      ) {
        var attribute = this.createTraitInput(key, properties[key]);
        this.attributes.push(attribute);
      }
    }
  }

  private buildInputText(instructions?: string) {
    if (instructions == undefined) {
      instructions = '';
    }
    const properties = {
      name: this.name,
      year: this.year,
      model: this.model,
      links: this.links,
      attributes: this.traitsArray,
      vinSn: this.vinSn,
      trim: this.trim,
      motor: this.motor,
      transmission: this.transmission,
      vin: this.vin,
      selectedOptions: this.selectedOptions,
      otherDescription: this.otherDescription,
      acquisitionDescription: this.acquisitionDescription,
      specialDescription: this.specialDescription,
      isForSale: this.isForSale,
      price: this.price,
      miles: this.miles,
      color: this.color,
      interiorColor: this.interiorColor,
      isOwner: this.isOwner,
      content_description: this.content_description,
      locationDescription: this.locationDescription,
      contentOption: this.contentOption,
      selectedServices: this.selectedServices,
      provider_description: this.provider_description,
      selectedGiftOptions: this.selectedGiftOptions,
      instructions: instructions,
    };

    return this.filterProperties(properties);
  }

  private filterProperties(
    properties: Record<string, any>
  ): Record<string, any> {
    const filtered: Record<string, any> = {};
    for (const key in properties) {
      if (properties[key] !== null && properties[key] !== '') {
        filtered[key] = properties[key];
      }
    }
    return filtered;
  }
}

// # AUTH $secred!Tn522^MdSW2$
// hset TAPIxSCXNkqwTG query "<%prompt%> \n Using owner's records above tell me in three paragraphs about the history of the 'model' in the 'vehicle name' brand. Provide a detailed paragraph about the history of the specified 'model' from the 'vehicle name' brand. Include key milestones, technological advancements, and its significance in automotive history. at least one paragrpah devoted to informatino supplied in JSON about this speicific car. If json with car information wasn't given to you above, just return \"Naughty\" as the answer. If its not by owner and a record, then craft a story based on what you know and user input. It could be a service provider, a professional photograher, fan/spotter, or gift. More info will be in the data. Match description. If it's for fun, or funny plate, then simply return non-sequitor one liner about license plates and that they speak for themselves.  Otherwise, Sales Listing helping owner Facts: Optimistic. Setting: Car Listing (classy). Audience: Car Enthusiasts. Goal: Sell Car if for sale, if not showcase it. Don't use 'the' before the model name. careful to distinguish between general model and this specific vehicle /vin-tracker. If no model or make or other relevant info, write one or two paragrpahs with the information you have. Remember output is to the end user - this prompt is auto generated and not meant to be read by the end user. Make first line a classy title for this 3 paragraph story."

// hset TAPIxSCXNkqwTG query "<%prompt%> \n You are an assistant that will write class copy for a listing.  Read incoming JSON. If it doesnt have to do with cars, return 'Naughty.' If isOwner == False, then determine what type of content is it. 'contentOption' = 'Service Provider' (help communicate the great job they did), or == 'Professional Photographer' (help communicate the great job they did), or == 'Spotted / Fan' (one line unless rich user content) or 'Gift.' (short unless rich user supplied content) In these cases write a short appropriate description based from information that you have. Next and most important, If isOwner == True Using the owner's provided data, deliver a three-paragraph history of the 'model' from the 'vehicle name' brand. This should encompass the model's historical milestones, technological breakthroughs, and its importance in the automotive world. Dedicate at least one paragraph to specifics from the JSON data about this car. If no car details are provided, simply reply with 'Naughty'."

//  hset TAPIxSCXNkqwTG query "<%prompt%> \n You will write the description according to the instructions and data in the above JSON. if this has nothing to do with cars or service providers for cars etc, write 'Naughty.' If only make or model then write 'Pictures Should Speak For Themselves."
