<p>test route works!</p>

<div class="buttons">
  <h1>Simple hover effects with <code>box-shadow</code></h1>
  <button class="fill">
    Push to start <br />
    your <BR /> ServiceRecords
  </button>
  <button class="pulse">
    Push to start <br />
    your <BR /> ServiceRecords
  </button>
  <button class="close">
    Push to start <br />
    your <BR /> ServiceRecords
  </button>
  <button class="raise">
    Push to start <br />
    your <BR /> ServiceRecords
  </button>
  <button class="up">
    Push to start <br />
    your <BR /> ServiceRecords
  </button>
  <button class="slide">
    Push to start <br />
    your <BR /> ServiceRecords
  </button>
  <button class="offset">
    Push to start <br />
    your <BR /> ServiceRecords
  </button>
</div>

<br />
<h1>Spinner2</h1>
<app-spinner2></app-spinner2>
