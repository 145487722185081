import { Component, OnInit } from '@angular/core';
import { OffchainService } from 'src/app/services/offchain.service';
import { MongoDBService } from 'src/app/services/mongo-dbservice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  password = '';
  isPasswordCorrect = false;
  vins: VinNftType[] = [];
  owners = ['TEST1', 'TEST2'];
  usernames: string[] = [];
  modalVisible = false;
  modalIndex: number;
  selectedOwner: string;
  customOwner: string;

  // For setting dashboard image
  uploadingFile: File | null = null;
  filedata: string | null = null;
  previewType: string = '';
  isVideo: boolean;
  mimeType: string | null = null;
  isLoading: boolean;

  title: string = 'Document Your Vehicle<br/>On The Blockchain';
  titleColor: string = '#fffc44';

  constructor(
    private readonly offchain: OffchainService,
    private readonly mongo: MongoDBService,
    private readonly toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  async checkPassword() {
    try {
      const res = await this.offchain.loginAdminUser(this.password);
      if (res.data === true) {
        this.isPasswordCorrect = true;
        this.loadDashboardSetting();
        this.loadData();
      } else {
        this.isPasswordCorrect = false;
      }
    } catch (e) {
      this.isPasswordCorrect = false;
    }
  }

  async loadData() {
    this.vins = await this.offchain.getAllNfts();
    console.log('Vins', this.vins);

    this.vins.forEach(async (element) => {
      if (element.owner) {
        var un = await this.offchain.getUsernameById(element.owner);
        this.usernames.push(un);
      } else {
        this.usernames.push('n/a');
      }
    });

    this.getOwners();
  }

  openModal(index: number) {
    this.modalVisible = true;
    this.modalIndex = index;
  }

  closeModal() {
    this.modalVisible = false;
    this.modalIndex = -1;
    this.selectedOwner = '';
    this.customOwner = '';
  }
  changeOwner(index: number) {
    this.closeModal();
  }

  async getOwners() {
    console.log('ATTEMPTING TO GET OWNERS');

    var a = await this.mongo.selectData('vinnftprofiles');
    console.log('OWNERS', a);
    var b = await this.mongo.getTables();
    console.log('Tables', b);
  }

  createNewUser(email: string, username: string, password: string) {
    this.offchain.createProfile(email, username, password);
  }

  handleFileInput(event: any) {
    const file: File = event.target.files[0];
    // Check file type
    const allowedImageTypes = [
      'image/svg+xml',
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/bmp',
      'image/gif',
    ];
    const allowedVideoTypes = ['video/webm', 'video/mp4'];
    const fileType = file.type;
    if (allowedImageTypes.includes(fileType)) {
      this.previewType = 'image';
      this.isVideo = false;
    } else if (allowedVideoTypes.includes(fileType)) {
      this.isVideo = true;
      this.previewType = 'video';
    } else {
      alert('Invalid file type. Please select a valid file type.');
      return;
    }
    this.mimeType = fileType;
    // Check file size
    const maxSize = 100 * 1024 * 1024; // 100MB
    const fileSize = file.size;
    if (fileSize > maxSize) {
      alert('File size exceeds the maximum limit of 100MB.');
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.filedata = e.target.result;
    };
    reader.readAsDataURL(file);
    this.uploadingFile = file;
  }

  async onSetDashboardImage() {
    let S3BucketImageLink;
    if (this.uploadingFile) {
      this.isLoading = true;
      let filePath =
        'images/' +
        Math.random() * 10000000000000000 +
        '_' +
        'S3BucketImageLink';
      try {
        S3BucketImageLink = await this.offchain.uploadToS3Service(
          this.uploadingFile,
          filePath
        );
        const setting = await this.offchain.setDashboardImageUrl({
          imageUrl: S3BucketImageLink.Location,
        });
        this.toastr.success('Dashboard image updated');
      } catch (e) {
        this.toastr.error('Error updating');
      } finally {
        this.isLoading = false;
      }
    }
  }

  async onSetDashboardTitleAndColor() {
    try {
      this.isLoading = true;
      const setting = await this.offchain.setDashboardTitleAndColor({
        title: this.title,
        titleColor: this.titleColor,
      });
      this.toastr.success('Dashboard title and color updated');
    } catch (e) {
      this.toastr.error('Error updating');
    } finally {
      this.isLoading = false;
    }
  }

  async loadDashboardSetting() {
    const setting = await this.offchain.getDashboardSetting();
    if (!setting) return;
    if (setting.hasOwnProperty('title')) this.title = setting.title;
    if (setting.hasOwnProperty('titleColor'))
      this.titleColor = setting.titleColor;
  }
}
