import { Component, OnInit } from '@angular/core';
import { OffchainService } from 'src/app/services/offchain.service';
import { Router } from '@angular/router';
import {
  HowItWorksData,
  IHowItWorksData,
} from 'src/assets/data/how-it-works-data';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/components/_modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  HowItWorks: IHowItWorksData[] = HowItWorksData;
  newVins: VinNftType[] = [];
  randomVins: VinNftType[] = [];

  featureVins: VinNftType[] = [];
  imgNewVin: VinNftType;
  loading: boolean = true;
  updateMasonryLayout: any;

  // For Dashboard Setting
  imageUrl: string = "url('../../../assets/images/new_banner.jpg')";
  title: string = 'Document Your Vehicle<br/>On The Blockchain';
  titleColor: string = '#fffc44';

  address: any;

  constructor(
    private readonly offchain: OffchainService,
    private readonly router: Router,
    public readonly auth: AuthService,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    try {
      this.loadDashboardSetting();
      this.loadData();
      this.setAddress();
    } catch (error) {
      console.log('init load error', error);
    }
  }

  pickRandom(allVins: VinNftType[], count = 10) {
    if (count > allVins.length) count = allVins.length;
    while (count > 0) {
      const idx = Math.floor(Math.random() * allVins.length);
      this.randomVins.push(allVins[idx]);
      allVins.splice(idx, 1);
      count--;
    }
    console.log(this.randomVins, 'randvins');
  }

  goDetail(cardId: number) {
    this.router.navigate([`/item/${cardId}`]);
  }

  loadText = 'Assembling latest ServiceRecords Components...';
  async loadData() {
    try {
      // Load latest vin nfts
      const allVins = await this.offchain.getAllNfts();
      this.loadText = 'Detailing and Polishing...';
      this.newVins = allVins.slice(0, 9);
      for (let idx = 0; idx < environment.featuredIds.length; idx++) {
        const fItem = await this.offchain.getNftById(
          environment.featuredIds[idx]
        );
        this.loadText =
          'Detailing and Polishing...(' +
          idx +
          '/' +
          environment.featuredIds.length +
          ')';
        if (fItem) {
          this.featureVins.push(fItem);
        }
      }
      console.log(allVins, 'allVins');
      this.pickRandom(allVins);
      console.log(this.newVins, this.featureVins, 'new & featured vins');
      this.imgNewVin = this.newVins[0];
    } catch (err) {
      console.log(err, 'Load Error');
      this.loading = false;
    }
    this.loading = false;
    setTimeout(() => {
      this.checkVideoLoaded();
    }, 1000);
  }

  async checkVideoLoaded() {
    const pageVideos = document.getElementsByTagName('video');
    const instance = this;
    instance.updateMasonryLayout = Date.now().toString();
    for (let i = 0; i < pageVideos.length; i++) {
      pageVideos[i].addEventListener('canplay', function () {
        console.log('can play', i);
        instance.updateMasonryLayout = Date.now().toString();
      });
    }
  }

  async loadDashboardSetting() {
    const setting = await this.offchain.getDashboardSetting();
    if (!setting) return;
    if (setting.hasOwnProperty('imageUrl'))
      this.imageUrl =
        setting.imageUrl || "url('../../../assets/images/new_banner.jpg')";
    if (setting.hasOwnProperty('title')) this.title = setting.title;
    if (setting.hasOwnProperty('titleColor'))
      this.titleColor = setting.titleColor;
  }

  async setAddress() {
    if (!window.ethereum) return;
    const account = await window.ethereum.request({ method: 'eth_accounts' });
    this.address = account[0];
  }

  openLogin() {
    this.modalService.open('login-modal');
  }
}
