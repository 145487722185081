<!-- How it works? -->
<div class="home-how text-center mt-20">
  <h1 class="px-4 font-semibold text-3xl md:text-4xl my-12 text-center">
    ServiceRecords&trade;: An Organizational Tool - How it Works?
  </h1>
  <h1
    class="px-4 font-semibold text-2xl md:text-4xl my-12"
    style="line-height: 150%"
  >
    ServiceRecords&trade; permanently documents a vehicle's life including
    pictures, videos, maintenance records, upgrades, repairs, and CARFAX&#174;
    reports using blockchain technology. <br />
    <br />
    We do all the work - you get a secure data vault and digital collectible
    garage for your rides on the blockchain.
  </h1>
  <div class="p-b-20 mt-50">
    <ul class="timeline">
      <li class="timeline-item">
        <div class="flex flex-col items-center item">
          <div class="flex relative w-full justify-end">
            <div class="topline w-1/2"></div>
          </div>
          <div class="timeline-badge raceFont1">1</div>
          <div class="horizontal-line"></div>
          <div class="timeline-panel text-center flex-col">
            <div class="font-bold text-[22px]">Select a Package</div>
            <p class="font-small mt-4" style="line-height: 150%">
              For $5, ServiceRecords offers an enthusiast level package to track
              your vehicle's history by forever linking the blockchain to a VIN.
              Upgraded packages included the ability to store service records
              and documents during the vehicle's life.
            </p>
          </div>
        </div>
      </li>
      <li class="timeline-item">
        <div class="flex flex-col items-center item">
          <div class="flex relative w-full">
            <div class="topline w-full"></div>
          </div>
          <div class="timeline-badge raceFont1">2</div>
          <div class="horizontal-line"></div>
          <div class="timeline-panel text-center">
            <div class="font-bold text-[22px]">Vehicle Details</div>
            <p class="font-small mt-4" style="line-height: 150%">
              Provide a photo of your ride, VIN, optional proof of ownership, a
              brief description of the vehicle and what makes it special. Option
              the ability to upload and store service records, repairs, and
              related historical documents important to the vehicle's life.
            </p>
          </div>
        </div>
      </li>
      <li class="timeline-item">
        <div class="flex flex-col items-center item">
          <div class="flex relative w-full">
            <div class="topline w-full"></div>
          </div>
          <div class="timeline-badge raceFont1">3</div>
          <div class="horizontal-line"></div>
          <div class="timeline-panel text-center">
            <div class="font-bold text-[22px]">Creation on the Blockchain</div>
            <p class="font-small mt-4" style="line-height: 150%">
              ServiceRecords will mint your vehicle on the blockchain and
              deliver instructions on how to hold your ServiceRecords in your
              digital collectible garage and provide updates as your vehicle's
              history evolves.
            </p>
          </div>
        </div>
      </li>
      <li class="timeline-item">
        <div class="flex flex-col items-center item">
          <div class="flex relative w-full">
            <div class="topline w-1/2"></div>
          </div>
          <div class="timeline-badge raceFont1">4</div>
          <div class="horizontal-line"></div>
          <div class="timeline-panel text-center">
            <div class="font-bold text-[22px]">
              After ServiceRecords is Created
            </div>
            <p
              class="font-small mt-4"
              style="line-height: 150%; text-justify: inter-word"
            >
              Refer back to your ServiceRecords Garage to access photos, media,
              records, repairs, registrations, important documents, and social
              commentary. Use ServiceRecords as an organizational tool while
              showcasing your vehicle digitally.
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
