import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VinNFTService } from 'src/app/services/vinNFT.service';
import { WalletService } from 'src/app/services/wallet.service';
import { OffchainService } from 'src/app/services/offchain.service';
import { CoinListService } from 'src/app/services/coinlist.service';
import { MathService } from 'src/app/services/math.service';
import { AuthService } from 'src/app/services/auth.service';
import { ModalService } from 'src/app/components/_modal';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { VindataserviceService } from 'src/app/services/vindataservice.service';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { OpenGraphService } from 'src/app/open-graph.service';
import { Title } from '@angular/platform-browser';
import { re } from 'mathjs';

@Component({
  selector: 'app-itemTransfer',
  templateUrl: './itemTransfer.component.html',
  styleUrls: ['./itemTransfer.component.scss'],
})
export class ItemTransferComponent implements OnInit, OnDestroy {
  @Input() singleItem: VinNftType;
  id: number;
  nftAddress: string = environment.vinNFTMAddressMatic;
  newComment: string;
  comments: VinComment[] = [];
  walletAddress: string | null;
  loading: boolean = true;
  processing: boolean = false;
  isYours: boolean = false;
  eComment: VinComment | null;
  offerAmount: number;
  currencyList: any = [];
  selectedCurrency: string;
  offerCurrency: string;
  offerCurrencybalance: number = 0;
  offerCurrencyApproved: number = 0;
  gallerySlides: any[] = [];
  galleryPos: number = 0;
  showWeb3Payment: boolean = false;

  qrCodeData: any = '';
  qrCodeDownloadLink: any;

  recordPopupType: string = 'ext_record';

  nftOwnerUsername: any = null;
  listPrice: string = '';
  showPasswordPopup: boolean = false;
  password: string = '';
  pwValidText: string = '';
  curPassword: string = '';
  formOldPassword: string = '';
  formNewPassword: string = '';
  formNewConfirmPassword: string = '';
  isUpdatingPassword: boolean = false;
  isFavorited: boolean = false;

  vinEstimate = '0';

  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;
  stripeError: any;
  stripe: any;
  card: any;
  cardHandler = this.onStripeChange.bind(this);
  stripeProcessing: boolean = false;
  mintFee: number = environment.mintFee.professional;

  // For Xfers and Claims
  xfer_recipient_email = '';
  xfer_holder_email = ''; // populated by user in the modal.
  xfer_memo = '';
  xfer_holder_password = '';

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private readonly wallet: WalletService,
    private readonly vinnft: VinNFTService,
    private readonly offchain: OffchainService,
    private readonly toastr: ToastrService,
    private readonly coinlist: CoinListService,
    public readonly auth: AuthService,
    private readonly math: MathService,
    private readonly vindata: VindataserviceService,
    private openGraphService: OpenGraphService,
    private titleService: Title,
    private stripeService: AngularStripeService,
    private http: HttpClient
  ) {}

  private getUri(): string {
    return environment.offchainApi;
  }

  ngOnInit() {
    this.id = parseInt(this.route.snapshot.paramMap.get('id') || '1');
    this.loadData().then(() => {
      this.titleService.setTitle(
        'ServiceRecords™ - Vehicle Provenance & Record Keeping for ' +
          this.singleItem?.name +
          '(' +
          this.getOwnerName() +
          ')'
      );
    });
  }

  async loadData(): Promise<any> {
    this.walletAddress = await this.wallet.getAccount();
    // Get NFT's Metadata
    if (!this.singleItem) {
      // const _vinItem = await this.vinnft.getVinItem(this.id);
      const _vinItem = await this.offchain.getNftById(this.id);
      if (!_vinItem) {
        this.toastr.error('Failed to fetch metadata');
        return;
      }
      this.singleItem = _vinItem;
      console.log(this.singleItem, 'singleItem');
      const url = this.route.pathFromRoot.toString();

      const title =
        'ServiceRecords - ' +
        this.singleItem.name +
        ' - Complete Vehicle Information On The Chain';
      const description = this.singleItem.description;
      const image = _vinItem.image;

      this.openGraphService.setMetaTags(url, title, description, image);
    }
    console.log(this.singleItem, 'Nft Item');
    // Get Username if owner is not wallet address
    if (this.singleItem.owner && !this.singleItem.owner.startsWith('0x')) {
      this.nftOwnerUsername = await this.offchain.getUsernameById(
        this.singleItem.owner
      );
    }
    // Check if this is yours ;)
    if (
      this.singleItem.owner?.toLowerCase() ===
        this.walletAddress?.toLowerCase() ||
      this.singleItem.owner?.toLowerCase() ===
        this.auth.curUser?._id?.toLowerCase()
    )
      this.isYours = true;
    // Check if this has password
    if (this.singleItem.hasPassword) {
      this.showPasswordPopup = true;
      var recordData = await this.offchain.getPrivateDataById(this.id);
      this.curPassword = recordData?.password ?? '';
    }
    // show price
    if (this.singleItem.vinSn && this.singleItem.vinSn.length == 17) {
      this.listPrice = 'CERTIFIED';
    }
    if (this.singleItem && this.singleItem.attributes) {
      //console.log(this.singleItem);
      this.singleItem.attributes.some((element) => {
        if (element.trait_type === 'Asking Price') {
          this.listPrice = element.value;
        }
      });
    }
    if (this.singleItem.price) {
      this.listPrice = this.singleItem.price;
    }

    await this.getComments();
    this.currencyList =
      await this.coinlist.getSupportedCurrencyDropDownByNetwork('MATIC');
    this.selectedCurrency = this.currencyList[0].id;
    this.onChangeCurrency();
    this.qrCodeData = `https://vin-tracker.com/item/${this.singleItem.id?.toString()}`;

    this.gallerySlides[0] = {
      content: this.singleItem?.image,
      isVideo: this.singleItem?.isVideo,
    };
    this.singleItem?.extras.map((extra) => {
      this.gallerySlides.push({
        content: extra.content,
        isVideo: extra.isVideo,
      });
    });

    if (this.singleItem.id) {
      this.vinEstimate = this.vindata.getVinEstimateById(this.singleItem.id);
    }

    if (this.auth.isLogin) {
      this.xfer_holder_email = this.auth?.curUser?.email;
      const favids = this.auth.curUser.favids;
      if (favids?.includes(this.id)) this.isFavorited = true;
      else this.isFavorited = false;
    }
    this.loading = false;
    return;
  }

  getOwnerName() {
    if (this.nftOwnerUsername) return this.nftOwnerUsername;
    if (!this.singleItem.owner) return;
    const walletLength = this.singleItem.owner.length;
    return (
      this.singleItem.owner.slice(0, 8) +
      '...' +
      this.singleItem.owner.slice(walletLength - 8, walletLength)
    );
  }

  onChangeURL(url: any) {
    this.qrCodeDownloadLink = url;
  }

  async enterPassword() {
    try {
      const res = await this.offchain.checkNftPassword(
        this.singleItem.id as number,
        this.password
      );
      this.showPasswordPopup = false;
      this.singleItem.records = res;
      console.log(res);
    } catch (error) {
      this.pwValidText = 'Wrong password!';
      const pwbox = document.getElementById('pwbox');
      pwbox?.classList.add('shakePasswordBox');
      setTimeout(() => {
        pwbox?.classList.remove('shakePasswordBox');
      }, 820);
    }
  }

  openZoom(_newPos: number) {
    this.galleryPos = _newPos;
    this.openModal('imageGalleryModal');
  }

  async toggleFavorite() {
    if (!this.auth.isLogin) {
      this.toastr.error('You have to login add favorite');
      return;
    }
    this.processing = true;
    if (this.isFavorited) {
      await this.removeFromFavorites();
    } else {
      await this.addToFavorites();
    }
    this.toastr.success('Saved!');
    this.processing = false;
  }

  async addToFavorites() {
    const res = await this.offchain.addToFavorites(
      this.id,
      this.auth.curUser._id
    );
    this.isFavorited = true;
  }

  async removeFromFavorites() {
    await this.offchain.removeFromFavorites(this.id, this.auth.curUser._id);
    this.isFavorited = false;
  }

  prevSlider() {
    if (this.galleryPos === 0) {
      this.galleryPos = this.gallerySlides.length - 1;
    } else this.galleryPos--;
  }

  nextSlider() {
    if (this.galleryPos === this.gallerySlides.length - 1) {
      this.galleryPos = 0;
    } else this.galleryPos++;
  }

  copyUrl() {
    navigator.clipboard
      .writeText(`https://vin-tracker.com/item/${this.id}`)
      .then(() => {
        this.toastr.success('Copied to clipboard');
      });
  }

  onChangeCurrency() {
    this.currencyList.forEach((item: any) => {
      if (item.id === this.selectedCurrency) {
        this.offerCurrency = item.name;
      }
    });
    this.getCurrencyBalance();
  }

  async getCurrencyBalance() {
    if (!this.walletAddress) return;
    this.offerCurrencybalance = await this.vinnft.getBalance(
      this.selectedCurrency
    );
    this.offerCurrencyApproved = await this.vinnft.getApprovedAmount(
      this.selectedCurrency
    );
    console.log(this.offerCurrencybalance, 'bal');
  }

  async approveToken() {
    const decimals = await this.vinnft.getDecimals(this.selectedCurrency);
    await this.vinnft.approveToken(
      this.math.toBlockchainValue(this.offerAmount, decimals)
    );
    setTimeout(async () => {
      this.offerCurrencyApproved = await this.vinnft.getApprovedAmount(
        this.selectedCurrency
      );
    }, 1500);
  }

  async getComments() {
    // Get Comments Data
    this.comments = await this.offchain.getCommentsByTokenId(this.id);
  }

  getCommentTime(_date: string): string {
    const commentTime = new Date(_date);
    return commentTime.toLocaleString();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    console.log('close modal', id);

    if (id == 'external-record-list-modal') {
      const element = document.querySelector('#vault');
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  async openUpgradeModal() {
    this.openModal('select-upgrade-mode-modal');
  }

  async mintPrimary() {
    if (!this.auth.isLogin) {
      this.toastr.warning('Please login first to mint NFT');
      this.modalService.open('login-modal');
      return;
    }
    const stripeInstance = await this.stripeService.setPublishableKey(
      environment.stripeInfo.PUBLISHABLE_KEY
    );
    this.closeModal('select-upgrade-mode-modal');
    this.stripe = stripeInstance;
    const elements = await stripeInstance.elements();
    this.card = await elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
    this.openModal('stripe-checkout-modal');
  }

  onStripeChange(error: any) {
    console.log(error, this, 'stripe change erorr');
    if (error.error) {
      this.stripeError = error.error.message;
    } else {
      this.stripeError = null;
    }
  }

  async onSubmit() {
    let data = {
      token_id: this.singleItem.id,
    };
    this.stripeProcessing = true;
    this.stripeError = null;
    try {
      const intent = await this.offchain.stripeCreateIntent(this.mintFee);
      const result = await this.stripe.confirmCardPayment(
        intent.client_secret,
        {
          payment_method: {
            card: this.card,
          },
        }
      );
      console.log(result, 'stripe confirm');
      if (result.error) {
        this.stripeError = result.error.message;
        throw Error;
      }
      if (result.paymentIntent?.status === 'succeeded') {
        let data = {
          token_id: this.singleItem.id,
        };
        const res = await this.offchain.setUpgrade(data);
        this.toastr.success(
          'Success! Your payment have been processed successfully! Please wait while ServiceRecords information is synchronised.'
        );
        this.closeModal('stripe-checkout-modal');
        this.loading = true;
      }
    } catch (error) {
      console.log(error, 'stripe confirm error');
      this.toastr.error('Stripe Error');
    }
    this.stripeProcessing = false;
  }

  async toggleWeb3() {
    const walletAddress = await this.wallet.getAccount();
    if (!walletAddress) {
      // No wallet connected
      await this.wallet.connectWallet();
    }
    const network = await this.wallet.getNetwork();
    if (network === 'UNSUPPORTED') await this.wallet.switchToMatic();
    this.showWeb3Payment = !this.showWeb3Payment;
  }

  async makeOffer() {
    if (!this.walletAddress && !this.auth.isLogin) {
      this.toastr.warning(
        'You have to login or connect wallet to submit offers'
      );
      return;
    }
    this.newComment = `Made Offer: $${this.offerAmount.toLocaleString()}`;
    await this.createComment('Offer');
    this.closeModal('make-offer-modal');
  }

  async createComment(type = 'Comment') {
    if (!this.walletAddress && !this.auth.isLogin) {
      this.toastr.error(
        'You have to login or connect wallet to submit comments'
      );
      return;
    }
    this.processing = true;
    await this.offchain.createComment(
      this.id,
      this.singleItem.name,
      this.newComment,
      this.auth.isLogin ? this.auth.curUser.username : this.walletAddress,
      this.singleItem.owner as string,
      type
    );
    this.newComment = '';
    this.getComments();
    this.toastr.success('Saved!');
    this.processing = false;
  }

  async editComment(_comment: VinComment) {
    this.eComment = {
      ..._comment,
    };
  }

  async deleteComment(_id: string) {
    this.processing = true;
    const isConfirmed = window.confirm(
      'You really want to delete this comment?'
    );
    if (isConfirmed) {
      await this.offchain.deleteComment(_id);
      this.getComments();
      this.toastr.success('Deleted!');
    }
    this.processing = false;
  }

  async updateComment() {
    if (!this.eComment) return;
    this.processing = true;
    await this.offchain.updateComment(
      this.eComment._id as string,
      this.eComment.comment
    );
    this.eComment = null;
    this.getComments();
    this.toastr.success('Updated!');
    this.processing = false;
  }

  sliceWallet(_addr: string) {
    return `${_addr.slice(0, 6)}...${_addr.slice(
      _addr.length - 6,
      _addr.length
    )}`;
  }

  convertString(text: string) {
    if (text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      text = text.replace(
        urlRegex,
        '<a href="$1" class="text-blue-500" title="Please click here to view more" target="_blank">$1</a>'
      );
      text = text.replace(/\n/g, '<br>');
    }
    return text;
  }

  ngOnDestroy() {
    this.titleService.setTitle(
      'ServiceRecords™ - Vehicle Provenance & Record Keeping'
    );
  }

  async aprove_vintracker() {
    let data = {
      name: this.singleItem.name,
      from: this.xfer_holder_email,
      token_id: this.singleItem.id,
      user_id: this.auth.curUser._id,
      owner_id: this.singleItem.owner,
    };
    const res = await this.offchain.sendApproveRequest(data);
    if (res.code == 'request') {
      this.toastr.success(
        'We have received your claim and have emailed the owner for confirmation'
      );
    } else if (res.code == 400) {
      this.toastr.error(res.msg);
    } else {
      this.toastr.success('Vinnft approveded');
    }
  }

  async deny_vintracker() {
    let data = {
      name: this.singleItem.name,
      from: this.xfer_holder_email,
      token_id: this.singleItem.id,
      user_id: this.auth.curUser._id,
      owner_id: this.singleItem.owner,
    };
    const res = await this.offchain.sendDenyRequest(data);
    if (res.code == 'request') {
      this.toastr.success(
        'We have received your claim and have emailed the owner for confirmation'
      );
    } else if (res.code == 400) {
      this.toastr.error(res.msg);
    } else {
      this.toastr.success('Vinnft rejected');
    }
  }
}
