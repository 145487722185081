<div class="profile section__padding !pt-16 mt-16">
  <div
    class="profile-bottom xl:w-[1156px] mx-auto lg:w-[900px] w-full relative"
  >
    <div class="px-6 md:px-0" *ngIf="!loading">
      <div
        class="text-center text-2xl sm:text-2xl flex flex-col sm:flex-row justify-center items-center font-bold default-font gap-3"
      >
        <!-- <app-start-vin-button></app-start-vin-button> -->
        <img
          src="../../../assets/images/vin-track-new-black.png"
          class="w-[250px] md:w-[300px]"
        />
        is the Zillow&REG; of vehicles.
      </div>
      <div class="filterBox px-6 shadow-lg flex items-center justify-between">
        <input
          type="text"
          placeholder="Search..."
          class="bg-transparent py-4 flex-1 outline-none"
          autoFocus="{true}"
          required
          [(ngModel)]="searchText"
          (change)="searchItems()"
        />
        <button class="flex items-center gap-2 explorer-filter">
          <ng-select
            [searchable]="false"
            [clearable]="false"
            [(ngModel)]="sortType"
            (change)="sortItems()"
          >
            <ng-option value="comment_count"> Comment Count </ng-option>
            <ng-option value="created_date"> Created Date </ng-option>
          </ng-select>
          <div (click)="sortDirection = -sortDirection; sortItems()">
            <i
              class="fas fa-caret-down text-lg"
              *ngIf="sortDirection === -1"
            ></i>
            <i class="fas fa-caret-up text-lg" *ngIf="sortDirection === 1"></i>
          </div>
        </button>
      </div>
      <ngx-masonry
        class="w-full mt-6"
        [ordered]="true"
        [updateLayout]="updateMasonryLayout"
      >
        <div
          class="md:mr-5 mb-3 md:mb-5 w-full md:w-[30%]"
          ngxMasonryItem
          *ngFor="let item of list.slice(0, showCount)"
        >
          <app-single-item-card [singleItem]="item">
            <img
              [defaultImage]="'../../../assets/beige_paper.jpg'"
              [lazyLoad]="image"
              [alt]="item.altText"
            />
            <!-- <img [src]="item.imageUrl" [alt]="item.altText"> -->
          </app-single-item-card>
        </div>
      </ngx-masonry>

      <div class="w-full flex justify-center" *ngIf="showCount < list.length">
        <button
          class="underline opacity-80 hover:opacity-100"
          (click)="showMore()"
        >
          Show More
        </button>
      </div>

      <div class="text-center mt-6">
        <app-start-vin-button></app-start-vin-button>
      </div>
    </div>
    <div
      class="flex flex-col items-center justify-center rounded-lg z-10 mt-40"
      *ngIf="loading"
    >
      <!-- <app-vin-spinner></app-vin-spinner> -->
      <app-spinner2></app-spinner2>
      <div class="mt-2 text-2xl font-bold">
        Preparing Digital ServiceRecords Showroom Database...
      </div>
    </div>
  </div>
</div>
