import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { type } from 'node:os';
import { environment } from 'src/environments/environment';
import FormData from 'form-data';
declare var AWS: any;

@Injectable()
export class OffchainService {
  private wyreSecretKey = environment.wyreInfo.SECRET_KEY;
  private wyreAccountId = environment.wyreInfo.ID;

  constructor(private http: HttpClient) {}

  private getUri(): string {
    return environment.offchainApi;
  }

  private getWyreUri(): string {
    return environment.wyreInfo.API_URL;
  }

  async getCommentsByTokenId(token_id: number): Promise<any> {
    return await this.http
      .get(this.getUri() + `/vincomment/getByTokenId/${token_id}`)
      .toPromise();
  }

  async getAllComments(): Promise<any> {
    return await this.http
      .get(this.getUri() + '/vincomment/getAll')
      .toPromise();
  }

  async createComment(
    token_id: number,
    name: string,
    comment: string,
    writer: string,
    owner: string,
    type: string
  ): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vincomment/create`,
        {
          token_id,
          comment,
          writer,
          owner,
          type,
          name,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return;
  }

  async deleteComment(_id: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vincomment/remove`,
        {
          _id,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return;
  }

  async updateComment(_id: string, comment: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vincomment/update`,
        {
          _id,
          comment,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return;
  }

  /* Wyre Apis */
  async getReservationId(_addr: string, fee: number): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getWyreUri()}/v3/orders/reserve?referrerAccountId=${
          this.wyreAccountId
        }&destCurrency=MUSDC&dest=matic:${_addr}&sourceCurrency=USD&country=US&sourceAmount=${fee}&paymentMethod=debit-card&amountIncludeFees=false`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.wyreSecretKey}`,
          },
          responseType: 'json',
        }
      )
      .toPromise();
    console.log(res, 'reservation');
    return res;
  }

  async wyreCheckout(_addr: string, fee: number) {
    const reservationInfo = await this.getReservationId(_addr, fee);
    const wyreHandler = document.getElementById('openWyreCheckout');
    localStorage.setItem('wyre-reservation-id', reservationInfo.reservation);
    wyreHandler?.click();
  }

  async primaryMint(tokenData: any): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinstripe/create`,
        {
          ...tokenData,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  async primaryUpdate(tokenData: any): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinstripe/update`,
        {
          ...tokenData,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  async stripeCreateIntent(amount: number): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinstripe/createIntent`,
        {
          amount,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  async createNft(
    token_id: number,
    owner: string,
    token_uri: string,
    metadata: string,
    privateMetadata: string,
    promoCode: string,
    email: string,
    password: string
  ): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinnft/create`,
        {
          token_id,
          owner,
          token_uri,
          metadata,
          privateMetadata,
          promoCode,
          email,
          password,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return;
  }

  async updatePassword(token_id: number, password: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinnft/updatePassword`,
        {
          token_id,
          password,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return;
  }

  async updateNft(
    token_id: number,
    owner: string,
    token_uri: string,
    metadata: string,
    private_token_metadata: string
  ): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinnft/update`,
        {
          nft: {
            token_id,
            owner,
            token_uri,
            metadata,
          },
          private: {
            token_id,
            private_token_metadata,
          },
        },
        { responseType: 'json' }
      )
      .toPromise();
    return;
  }

  async checkNftPassword(token_id: number, password: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinnft/checkPassword`,
        {
          token_id,
          password,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  async getPrivateDataById(token_id: number): Promise<any> {
    const res = await this.http
      .get(this.getUri() + `/vinnft/getPrivate/${token_id}`)
      .toPromise();
    return res;
  }

  async getNftById(token_id: number): Promise<any> {
    let nft: any = await this.http
      .get(this.getUri() + `/vinnft/getByTokenId/${token_id}`)
      .toPromise();
    return {
      id: nft.token_id,
      owner: nft.owner,
      hasPassword: nft.hasPassword,
      comments: nft.comments,
      ...JSON.parse(nft.metadata),
    };
  }

  async getNftbyOwner(owner: string): Promise<any> {
    let nfts: any = await this.http
      .get(this.getUri() + `/vinnft/getByOwner/${owner}`)
      .toPromise();
    const res: any[] = [];
    nfts.map((nft: any) => {
      const metadata = JSON.parse(nft.metadata);
      res.push({
        id: nft.token_id,
        owner: nft.owner,
        ...metadata,
      });
    });
    return res;
  }

  async getAllNfts(): Promise<any> {
    const nfts: any = await this.http
      .get(this.getUri() + `/vinnft/getAll`)
      .toPromise();
    const res: VinNftType[] = [];
    const allComments = await this.getAllComments();
    for (let idx = 0; idx < nfts.length; idx++) {
      const nft = nfts[idx];
      // Skip NFTs with token_id present in environment.deleted_ids[]
      if (environment.deleted_ids.includes(nft.token_id)) {
        continue;
      }

      const comments = allComments.filter(
        (_cmt: any) => _cmt.token_id === nft.token_id
      );
      const metadata = JSON.parse(nft.metadata);
      res.push({
        id: nft.token_id,
        owner: nft.owner,
        ...metadata,
        comments,
      });
    }
    return res;
  }

  async createProfile(
    email: string,
    username: string,
    password: string
  ): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinprofile/create`,
        {
          email,
          username,
          password,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  async checkUser(email: string, hash: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinprofile/checkUser`,
        {
          email,
          hash,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  async getUsernameById(_id: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinprofile/getNameById`,
        {
          _id,
        },
        { responseType: 'json' }
      )
      .toPromise();
    // console.log(res, 'getNameById');
    return res?.name;
  }

  async loginUser(email: string, password: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinprofile/login`,
        {
          email,
          password,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  async loginAdminUser(password: string): Promise<any> {
    const res: any = await this.http
      .post(
        `${this.getUri()}/vinprofile/admin`,
        {
          password,
        },
        { responseType: 'json' }
      )
      .toPromise();
    return res;
  }

  /* Pinata Apis */
  async uploadToPinata(uploadFile: any, name: string): Promise<any> {
    var data = new FormData();
    data.append('file', uploadFile);
    data.append('pinataOptions', '{"cidVersion": 1}');
    data.append(
      'pinataMetadata',
      `{"name": "${name}", "keyvalues": {"company": "Vinnft"}}`
    );
    const res: any = await this.http
      .post('https://api.pinata.cloud/pinning/pinFileToIPFS', data, {
        headers: {
          Authorization: `Bearer ${environment.PINATA_API_JWT}`,
          // 'Content-Type': `multipart/form-data; boundary=${data._boundary}`
        },
        responseType: 'json',
      })
      .toPromise();
    console.log(res, 'uploadToPinata');
    return `${environment.PINATA_DIGIBLE_URL}/${res.IpfsHash}`;
  }

  async uploadToHeroku(uploadFile: any, name: string): Promise<any> {
    var data = new FormData();
    data.append('file', uploadFile);
    const res: any = await this.http
      .post(`${this.getUri()}/vinnft/upload`, data)
      .toPromise();
    console.log(res, 'uploadToHeroku');
    return `${environment.offchainApi}/${res.fileName}`;
  }

  async uploadJsonToHeroku(token_metadata: any): Promise<any> {
    const res: any = await this.http
      .post(`${this.getUri()}/vinnft/uploadJson`, token_metadata)
      .toPromise();
    console.log(res, 'uploadToPinata');
    return `${environment.offchainApi}/${res.fileName}`;
  }

  async uploadToS3Service(file: any, filePath: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const contentType = file.type;
      const bucket = new AWS.S3({
        accessKeyId: 'AKIAXA7JFJRXEWWF7EOY', // ACCESS_KEY_ID
        secretAccessKey: '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', // SECRET_ACCESS_KEY
        region: 'us-east-1', // BUCKET_REGION
      });
      const params = {
        Bucket: 'www.vintracker.com', //BUCKET_NAME
        Key: filePath,
        Body: file,
        ACL: 'bucket-owner-full-control',
        ContentType: contentType,
      };
      bucket.upload(params, function (err: any, data: any) {
        if (err) {
          reject(err);
        }
        resolve(data);
      });
    });
  }

  async uploadJsonToPinata(token_metadata: any): Promise<any> {
    const res: any = await this.http
      .post('https://api.pinata.cloud/pinning/pinJSONToIPFS', token_metadata, {
        headers: {
          Authorization: `Bearer ${environment.PINATA_API_JWT}`,
          // 'Content-Type': `application/json`
        },
        responseType: 'json',
      })
      .toPromise();
    console.log(res, 'uploadToPinata');
    return `${environment.PINATA_DIGIBLE_URL}/${res.IpfsHash}`;
  }

  async getOwnerData(): Promise<any> {
    const res = await this.http
      .get(`${this.getUri()}/vinnftprofiles/getAll`)
      .toPromise();
    console.log(res, 'getDataForAllIdsFromVinnftProfiles');
    return res;
  }

  async createArticle(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinarticle/create`, data)
      .toPromise();
    console.log(res, 'create new article');
    return res;
  }

  async getAllArticles(): Promise<any> {
    const res = await this.http
      .get(`${this.getUri()}/vinarticle/getAll`)
      .toPromise();
    console.log(res, 'getAllArticles');
    return res;
  }

  async getLatestArticles(): Promise<any> {
    const res = await this.http
      .get(`${this.getUri()}/vinarticle/getLatest`)
      .toPromise();

    console.log(res, 'getLatestArticles');
    return res;
  }

  async getRelevantArticles(id: any): Promise<any[]> {
    const res: any = await this.http
      .get(`${this.getUri()}/vinarticle/getLatest`)
      .toPromise();

    console.log(res, 'getRelevantArticles');
    return res.filter((item: any) => item._id !== id);
  }

  async removeArticle(id: any): Promise<any> {
    const res = await this.http
      .delete(`${this.getUri()}/vinarticle/remove/${id}`)
      .toPromise();

    console.log(res, 'removeArticle');
    return res;
  }

  async updateArticle(data: any): Promise<any> {
    console.log(data, 'updateArticle Data');
    const res = await this.http
      .put(`${this.getUri()}/vinarticle/update`, data)
      .toPromise();

    console.log(res, 'updateArticle');
    return res;
  }

  async getArticle(id: any): Promise<any> {
    const res = await this.http
      .get(`${this.getUri()}/vinarticle/getArticle/${id}`)
      .toPromise();

    return res;
  }

  async likeArticle(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinarticle/like`, data)
      .toPromise();

    return res;
  }

  async likeComment(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinarticle/likeComment`, data)
      .toPromise();

    return res;
  }

  async createCommentOfArticle(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinarticle/comment`, data)
      .toPromise();
    return res;
  }

  async addToFavorites(token_id: number, owner: string): Promise<any> {
    const res: any = await this.http
      .put(`${this.getUri()}/vinnft/addToFavorites`, {
        token_id,
        owner,
      })
      .toPromise();
    return res;
  }

  async removeFromFavorites(token_id: number, owner: string): Promise<any> {
    const res: any = await this.http
      .delete(
        `${this.getUri()}/vinnft/removeFromFavorites?token_id=${token_id}&owner=${owner}`
      )
      .toPromise();
    return res;
  }

  async sendTransferRequest(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinnft/transfer`, data, { responseType: 'json' })
      .toPromise();
    console.log(res, 'send transfer request');
    return res;
  }

  async sendClaimRequest(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinnft/claim`, data, { responseType: 'json' })
      .toPromise();
    console.log(res, 'send claim request');
    return res;
  }
  async sendApproveRequest(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinnft/approve`, data, { responseType: 'json' })
      .toPromise();
    return res;
  }
  async sendDenyRequest(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinnft/deny`, data, { responseType: 'json' })
      .toPromise();
    return res;
  }

  async setUpgrade(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinnft/setUpgrade`, data, {
        responseType: 'json',
      })
      .toPromise();
    console.log(res, 'send transfer request');
    return res;
  }

  async removeVinNft(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinnft/remove`, data, { responseType: 'json' })
      .toPromise();
    return res;
  }

  async removeVinNftByTokenId(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/vinnft/removeByTokenId`, data, {
        responseType: 'json',
      })
      .toPromise();
    return res;
  }

  async getDashboardSetting(): Promise<any> {
    return await this.http
      .get(`${this.getUri()}/dashboardsetting/setting`)
      .toPromise();
  }

  async setDashboardImageUrl(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/dashboardsetting/url`, data, {
        responseType: 'json',
      })
      .toPromise();
    return res;
  }

  async setDashboardTitleAndColor(data: any): Promise<any> {
    const res = await this.http
      .post(`${this.getUri()}/dashboardsetting/title`, data, {
        responseType: 'json',
      })
      .toPromise();
    return res;
  }
}
