<router-outlet style="overflow: hidden" *ngIf="!loading"></router-outlet>
<jw-modal id="login-modal">
  <button
    class="relative top-2 right-2 text-2xl text-black float-right"
    (click)="closeModal('login-modal')"
  >
    <i class="fas fa-times"></i>
  </button>
  <div class="flex flex-col items-center">
    <h2 class="text-2xl font-semibold mb-4 raceFont1">LOGIN</h2>
    <input
      name="email"
      class="vin-input w-full mt-6"
      placeholder="Email..."
      [(ngModel)]="userEmail"
    />
    <input
      name="password"
      type="password"
      class="vin-input w-full mt-4"
      placeholder="Password..."
      [(ngModel)]="userPw"
    />
    <button
      type="button"
      (click)="login()"
      class="bg-redLeather rounded-xl py-4 px-12 text-white font-semibold text-sm mt-6"
    >
      {{ isProcessing ? "Processing..." : "Login" }}
    </button>
    <button
      class="mt-4 underline text-blue-800 text-sm font-semibold"
      (click)="openSignup()"
    >
      No account yet? Create an account now
    </button>
  </div>
</jw-modal>
<jw-modal id="signup-modal">
  <button
    class="relative top-2 right-2 text-2xl text-black float-right"
    (click)="closeModal('signup-modal')"
  >
    <i class="fas fa-times"></i>
  </button>
  <div class="flex flex-col items-center">
    <h2 class="text-2xl font-semibold mb-4 raceFont1">SIGNUP</h2>
    <input
      name="username"
      class="vin-input w-full mt-6"
      placeholder="Username..."
      [(ngModel)]="userName"
    />
    <input
      name="email"
      class="vin-input w-full mt-4"
      placeholder="Email..."
      [(ngModel)]="userEmail"
    />
    <input
      name="password"
      type="password"
      class="vin-input w-full mt-4"
      placeholder="Password..."
      [(ngModel)]="userPw"
    />
    <input
      type="password"
      class="vin-input w-full mt-4"
      placeholder="Confirm Password..."
      [(ngModel)]="userPwConfirm"
    />
    <button
      type="button"
      (click)="register()"
      class="bg-redLeather rounded-xl py-4 px-12 text-white font-semibold text-sm mt-6"
    >
      {{ isProcessing ? "Processing..." : "Register" }}
    </button>
    <button
      class="mt-4 underline text-blue-800 text-sm font-semibold"
      (click)="openLogin()"
    >
      You have an account? Login now
    </button>
  </div>
</jw-modal>
