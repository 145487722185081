<div class="xl:w-[1156px] mx-auto lg:w-[900px] w-full pt-8">
  <div *ngIf="!isPasswordCorrect" class="container">
    <h1>Password Protected Page ACCESS IS LOGGED</h1>
    <input type="password" [(ngModel)]="password" />

    <button (click)="checkPassword()">Submit</button>
  </div>

  <div *ngIf="isPasswordCorrect" class="mb-6">
    <div
      class="flex flex-col loadingBox absolute inset-0 items-center pt-32 rounded-lg z-10"
      *ngIf="isLoading"
    >
      <app-vin-spinner></app-vin-spinner>
      <div class="mt-2 text-2xl font-bold text-center px-2">
        Updating dashboard settings
      </div>
    </div>

    <h1>ADMIN PANEL ACCESS IS LOGGED</h1>

    <div
      *ngIf="isPasswordCorrect"
      class="mt-6 flex items-center justify-center w-full"
    >
      <label *ngIf="!uploadingFile" for="upload" class="upload-img-show">
        <h3>JPG, PNG, GIF, SVG, WEBM, MP3, MP4. Max 100mb.</h3>
        <img
          src="../../../assets/images/carUpload.jpeg"
          class="cursor-pointer"
          alt="Please upload car photos or video for your dashboard by pressing here."
          style="max-width: 50%; max-height: 50%; object-fit: contain"
        />
        <p>Drag & Drop File to Set Dashboard image</p>
      </label>
      <input
        type="file"
        id="upload"
        (change)="handleFileInput($event)"
        #fileInput
        class="custom-file-input hidden"
        accept="image/*, video/*"
      />
      <div *ngIf="uploadingFile" class="upload-img-show">
        <label for="upload" class="cursor-pointer">Upload Again</label>
        <img
          src="{{ filedata }}"
          class="w-[500px] h-[150px] object-scale-down"
          *ngIf="!isVideo"
        />
        <video
          loop
          controlsList="nodownload"
          class="w-[500px] object-contain"
          controls
          *ngIf="isVideo"
          [src]="filedata"
        >
          <source type="video/webm" [src]="filedata" />
          <source type="video/mp4" [src]="filedata" />
        </video>
      </div>
    </div>
    <div
      *ngIf="isPasswordCorrect"
      class="mb-6 flex items-center justify-center w-full"
    >
      <button type="button" (click)="onSetDashboardImage()">
        SET DASHBOARD IMAGE
      </button>
    </div>

    <div
      *ngIf="isPasswordCorrect"
      class="mt-6 mb-3 flex items-center justify-center w-full gap-6"
    >
      <div class="flex items-center justify-center w-3/4 gap-3">
        <label>Title:</label>
        <input type="text" id="title" name="title" [(ngModel)]="title" />
      </div>
      <div class="flex items-center justify-center w-1/4 gap-3">
        <label for="titleColor">Color:</label>
        <input
          type="color"
          id="titleColor"
          name="titleColor"
          [(ngModel)]="titleColor"
        />
      </div>
    </div>
    <div
      *ngIf="isPasswordCorrect"
      class="mb-6 flex items-center justify-center w-full"
    >
      <button type="button" (click)="onSetDashboardTitleAndColor()">
        SET TITLE AND COLOR
      </button>
    </div>
    <div>
      CREATE NEW USER:
      <form>
        <div>
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" />
        </div>
        <div>
          <label for="username">Username:</label>
          <input type="text" id="username" name="username" />
        </div>
        <div>
          <label for="password">Password:</label>
          <input type="password" id="password" name="password" />
        </div>
        <button
          type="button"
          onclick="createNewUser(document.getElementById('email').value, document.getElementById('username').value, document.getElementById('password').value)"
        >
          CREATE NEW USER
        </button>
      </form>
    </div>

    <table>
      <tr>
        <th>ID</th>
        <th>VIN-NAME</th>
        <th>VIN-OWNER</th>
        <th>OWNERS U/N</th>

        <th>Action</th>
      </tr>
      <tr *ngFor="let vin of vins; let i = index">
        <td>{{ vin.id }}</td>
        <td>{{ vin.name }}</td>
        <td>{{ vin.owner }}</td>
        <td>{{ usernames[i] }}</td>
        <td>
          <button (click)="openModal(i)">Change Owner</button>
          <div *ngIf="modalVisible && modalIndex === i">
            <select [(ngModel)]="selectedOwner">
              <option *ngFor="let owner of owners">{{ owner }}</option>
            </select>
            <input
              type="text"
              [(ngModel)]="customOwner"
              *ngIf="selectedOwner === 'Custom Owner'"
            />
            <button (click)="changeOwner(i)">Save</button>
            <button (click)="closeModal()">Cancel</button>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>
